@mixin txtbox {
  background-color: var(--txtbox-enabled);
  color: var(--primaryTextColor);
  font-size: var(--txtbox-font-size);
  line-height: var(--txtbox-line-height);
  font-family: var(--txtbox-font-family);
}

.form-control {
  @include txtbox();

  &.lg {
    max-width: 428px;
  }

  &.is-invalid {
    background-image: none;
  }
}

input {
  width: 100%;
}

textarea {
  &.w-100 {
    max-width: 100% !important;
  }

  &:read-only {
    background-color: var(--txtbox-disabled);
    box-shadow: unset;
  }
}

input,
select,
textarea {
  @include txtbox();

  font-style: normal;
  letter-spacing: 0.0025em;
  padding: 10px;
  box-shadow:
    0 -1px 1px var(--txtbox-border) inset,
    0 1px 0 #fff;
  border-radius: 8px;

  &.form-control {
    border: 1px solid transparent;
  }

  &:not(.disabled):not(input[type='checkbox']):not(:read-only):hover {
    @include input-hover();
  }

  &:not(.disabled):not(input[type='checkbox']):not(:read-only):focus {
    @include input-focus();
  }

  &.is-invalid,
  &.is-invalid:not(.disabled):not(input[type='checkbox']):not(:read-only):focus {
    border-color: var(--negativeColor) !important;
    outline-color: rgba(231, 29, 54, 0.2);
  }

  &::placeholder {
    font-family: var(--txtbox-font-family);
    color: var(--disabledTextColor);
    letter-spacing: 0.0025em;
  }

  &.disabled {
    pointer-events: none;
    background-color: var(--txtbox-disabled);
    color: var(--disabledTextColor);
  }
}

/* --- box with icon--- */
.search-input {
  position: relative;
  width: fit-content;

  i-feather {
    position: absolute;
    right: 12px;
    bottom: 7px;
    color: var(--secondaryTextColor);
  }

  input.form-control {
    width: 100%;
    padding-right: 36px;
    margin-right: 0 !important;
  }

  &.search-100 {
    .form-control {
      max-width: none;
      width: 100%;
    }
  }
}

/* ---Form group start--- */
.form-group {
  .custom-select-arrow {
    position: relative;

    &.right-0 {
      &::after {
        right: 10px;
      }

      .form-control {
        margin-right: 0;
      }
    }

    select {
      padding-right: 25px;
      box-shadow: 0 -1px 1px var(--txtbox-border) inset;
      appearance: none;
      background-image: url(../../images/icons/select-arrow.svg);
      background-repeat: no-repeat;
      background-position: 95% 7px;
      background-size: 20px;

      @include ellipsis();

      &.disabled,
      &:disabled {
        background-image: url(../../images/icons/select-arrow-disabled.svg);
      }

      &:required:invalid {
        color: var(--disabledTextColor);
      }

      option {
        @include txtbox();

        background-color: var(--fgColor);
      }

      option[value=''][disabled] {
        background-color: var(--fgColor) !important;
      }

      &.is-invalid {
        border-color: var(--negativeColor) !important;

        &:focus {
          border-color: var(--negativeColor) !important;
          outline-color: rgba(231, 29, 54, 0.2);
        }
      }
    }
  }

  input,
  select,
  textarea {
    width: auto;
    margin-right: 15px;
    max-width: 210px;

    &:not(input[type='checkbox'], input[type='radio']):focus {
      @include input-focus();
    }

    // &:not(.disabled):not(input[type='checkbox']):not(:read-only):hover {
    //   @include input-hover();
    // }
  }

  &-inline {
    @include flex(row, null, flex-start);

    margin-bottom: 16px;

    .form-group + .form-group {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/* ---Form group end--- */
.form-group {
  .mat-mdc-select-panel {
    border-radius: 8px !important;
    margin-top: 47px;
    width: 240px !important;
  }
}

.mat-mdc-select-panel {
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: var(--box-shadow) !important;
}

.mat-mdc-option {
  color: var(--primaryTextColor) !important;
  font-family: font-regular, sans-serif !important;
  height: auto;
  min-height: 33px !important;
  padding-block: 3px !important;

  .mat-pseudo-checkbox-minimal {
    color: var(--primaryTextColor);
  }

  span {
    @include ellipsis();
  }
}

.inline-field-group {
  display: flex;
  gap: 16px;
  align-items: center;

  .form-control {
    margin-right: 0;
  }

  .form-group + .form-group {
    margin-top: 0;
  }

  .custom-select-arrow::after {
    right: 10px;
  }
}

.time-input {
  position: relative;
  max-width: 100%;

  i-feather[name='calendar'],
  i-feather[name='clock'] {
    right: 12px;
    left: initial;
    top: 6px;
    position: absolute;
    color: var(--secondaryTextColor);
  }
}

label[required]::after,
.dob-required > label:after {
  content: '*';
  color: var(--secondaryTextColor);
}

.count-input {
  width: 35px !important;
  padding-inline: 0;
  text-align: center;
}

/* inline select box */
.inline-select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ngb-tp-input {
  width: 100% !important;
  margin-right: 0 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* time input */

.time-picker-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 115px;

  &.form-control {
    border: 1px solid transparent !important;
    box-shadow:
      0 -1px 1px var(--txtbox-border) inset,
      0 1px 0 #fff;
    border-radius: 8px;
  }

  app-timepicker {
    position: absolute;
    left: 0;
    top: 6px;
    width: 100%;
  }

  .time-value {
    white-space: nowrap;
  }
}

.input-icon-inside {
  position: relative;

  i-feather {
    position: absolute;
    right: 12px;
    bottom: 7px;
  }
}

/* For date with icon */

.input-icon {
  position: relative;
  width: fit-content;

  .icon {
    position: absolute;
    right: 20px;
    top: 9px;
  }
}

/*
@include media('down', md) {
  .inline-field-group {
    flex-direction: column;
    align-items: flex-start;

    &.input-with-unit {
      align-items: flex-start;
    }

    .form-group {
      width: 100%;
    }
  }
}
*/

@include media('down', sm) {
  .inline-field-group {
    flex-direction: column;
    align-items: flex-start;

    &.input-with-unit {
      align-items: flex-start;
    }

    .form-group {
      width: 100%;
    }
  }

  input,
  select,
  textarea {
    &:not(input[type='checkbox']):not(input[type='radio']):not(.reminder-input):not(.setting-notification-2 select) {
      width: 100% !important;
      max-width: 100%;
      margin-right: 0;

      /* "sm-50" this class is only for input size in small screen to 50% width */

      &.sm-50 {
        width: 50% !important;
      }
    }
  }

  .time-input {
    i-feather[name='calendar'],
    i-feather[name='clock'] {
      right: 8px;
    }
  }
}
