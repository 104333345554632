.mat-mdc-menu-panel,
.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--box-shadow);
  border-radius: 16px !important;

  .mat-mdc-menu-item {
    height: auto;
    min-height: auto !important;
    padding: 5px 24px;

    .mat-mdc-menu-item-text,
    .mat-mdc-menu-item-text span {
      font-family: var(--medium) !important;
      color: var(--secondaryTextColor) !important;
      letter-spacing: 0.0025em !important;
      font-size: var(--body-font-size);
      line-height: 18px;
    }

    i-feather {
      margin-right: 14px;
    }
  }
}

.mat-message-dropdown {
  &.mat-mdc-menu-panel {
    box-shadow:
      0 4px 20px 0 rgba(0, 0, 0, 0.06),
      0 11px 15px 0 rgba(0, 0, 0, 0.07),
      0 6px 7px 0 rgba(0, 0, 0, 0.1);
    min-height: auto;

    .mat-mdc-menu-item {
      @include body-text;
      @include align-center;

      font-family: var(--medium);
      border-radius: 0;
      padding: 5px 10px 5px 20px;
    }
  }
}

.mat-icon {
  padding-top: 3px;
}

.mat-mdc-button {
  font-family: var(--regular) !important;
}

.mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-button-touch-target {
  padding: 0;
  width: 40px !important;
  height: 40px !important;
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  display: none !important;
}

@include media('down', sm) {
  .mat-mdc-menu-panel .mat-mdc-menu-item {
    padding: 5px 12px;
  }
}
