$minWidth: (80, 90, 100, 120, 130, 140, 150, 180, 200, 220, 250, 270, 300, 350, 400, 450, 500);

@each $value in $minWidth {
  .min-w-#{$value} {
    min-width: $value + px !important;
  }
}

/* output classes - 
.min-w-80
.min-w-90
.min-w-100
.min-w-120
.min-w-130
.min-w-140
.min-w-150
.min-w-180
.min-w-200
.min-w-220
.min-w-250
.min-w-270
.min-w-300
.min-w-350
.min-w-450
.min-w-500
*/
