@include media('down', md) {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface.mdc-dialog__surface {
        padding: map-get($content-inline-padding, sm) !important;
      }

      .mat-mdc-dialog-content {
        margin-inline: -(map-get($content-inline-padding, sm));
        padding-inline: map-get($content-inline-padding, sm);
      }
    }
  }

  .full-screen-dialog {
    &:not(.video-lobby-dialog:has(.pip-card)) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100%;
      height: 100%;

      .mat-mdc-dialog-container {
        padding: 0 !important;
        padding-inline: 0;
        border-radius: 0 !important;
        height: 100vh !important;

        .mdc-dialog__container {
          .mat-mdc-dialog-surface.mdc-dialog__surface {
            box-shadow: none !important;
            max-height: 100vh !important;
            min-height: 100vh;
            border-radius: 0 !important;
          }
        }
      }
    }

    .mat-mdc-dialog-content {
      max-height: 100% !important;
      overflow: visible !important;
      padding: 0;
    }

    .mat-mdc-dialog-content-scroll {
      display: flex;
      flex-direction: column;
      max-height: 100%;

      .mat-mdc-dialog-content {
        flex-grow: 1;
        max-height: none !important;
        overflow: auto !important;
      }
    }
  }
}

@include media('down', sm) {
  @include res-inline-input();
}

/* show / hide ui between desktop and mobile view */

.view-only-mob,
.view-below-lg,
.view-below-tab {
  display: none !important;
}

@include media('down', lg) {
  .view-below-lg {
    display: flex !important;
  }

  .lg-hide {
    display: none !important;
  }

  .lg-show,
  .view-below-tab {
    display: block !important;
  }

  .view-above-lg {
    display: none !important;
  }
}

@include media('down', md) {
  .view-only-desktop {
    display: none !important;
  }

  .view-only-mob {
    display: flex !important;
  }

  .mob-hide {
    display: none !important;
  }

  .mob-show {
    display: block !important;
  }

  .mob-show-flex {
    display: flex !important;
  }
}
