img {
  max-width: 100%;

  &.pdf {
    width: 26px;
  }
}

/* --- Attachment Icons --- */

.attachment-icons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .attachment-icons-item {
    @include size(48px);
    @include flex-center();

    min-width: 48px;
    border-radius: 10px;
    background-color: #f2f2f2;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }

    i-feather.attachment-cancel {
      position: absolute;
      height: 18px;
      width: 18px;
      top: -8px;
      right: -8px;
      cursor: pointer;
      fill: var(--fgColor);
    }

    .attachment-icons-spinner {
      position: absolute;
      opacity: 0.5;
    }
  }
}

.message-ck-editor p {
  color: var(--secondaryTextColor);
}

.message-outer-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
}

.message-wrapper {
  @include flex(null, null, flex-start);

  height: calc(100% - 40px);
  gap: 16px;
  color: var(--secondaryTextColor);

  .message-list {
    max-height: calc(100% - 50px);
    overflow-y: auto;
    padding-bottom: 1px;

    .message-item {
      padding: 20px;
      border: 1px solid var(--separatorColor);

      @include align-center(14px);

      & + .message-item {
        border-top: none;
      }

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: #f2f2f2;
      }
    }

    .message-icon {
      @include size(37px);

      border-radius: 50%;
      overflow: hidden;
    }
  }

  .message-details {
    border: 1px solid var(--separatorColor);
    padding: 12px 20px;
    flex-grow: 1;
    height: 100%;
    width: calc(100% - 780px);
    min-width: 350px;

    .message-user {
      border-bottom: 1px solid var(--separatorColor);
      padding-bottom: 12px;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 6px;

        @include line-clamp(1, hidden);
      }
    }

    .msg-user-name-icon {
      @include line-clamp(1, hidden);

      font-size: 16px;

      .msg-user-icon {
        margin-right: 12px;
      }
    }

    .message-history {
      overflow-y: auto;
      display: flex;
      flex-direction: column-reverse;
      transition: height 1s ease-in-out;

      &.no-write-permission {
        height: 78%;
      }
    }

    .full-message-icon {
      @include size(37px);

      border-radius: 50%;
      overflow: hidden;
    }

    .message-footer {
      border-top: 1px solid var(--separatorColor);
      background-color: var(--fgColor);
      padding-top: 12px;
      padding-bottom: 0;
      position: relative;

      .message-footer-main {
        display: flex;
        align-items: flex-start;
        gap: 13px;
      }

      .attachment-icons {
        gap: 8px;
        margin-top: 18px;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 8px;
      }

      .message-editor {
        flex-grow: 1;
      }

      button {
        padding-block: 8px;
      }
    }
  }

  label {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }

  .new-message {
    textarea {
      max-width: 100%;
      width: 100%;
    }
  }

  .new-message-footer {
    display: flex;
    border-top: 1px solid var(--separatorColor);
    margin-top: 23px;
    padding: 20px 0 5px;
    justify-content: space-between;

    .new-message-icons {
      display: flex;
      gap: 12px;
      color: var(--secondaryTextColor);
    }
  }

  .new-message-template {
    display: flex;
    gap: 40px;
  }

  .unread-count {
    background-color: var(--negativeColor);
    border-radius: 50rem;
    padding-inline: 3px;
    font-size: 11px;
    font-family: 'font-bold', sans-serif;
    color: var(--whiteTextColor);
    text-align: center;
    height: 16px;
    min-width: 16px;
  }

  .full-message-item {
    margin-bottom: 12px;
    margin-top: 1px;
    padding-bottom: 20px;

    .full-message-content {
      display: flex;
      width: 60%;
      flex-direction: column;
    }

    .full-message-content-inner {
      display: flex;
    }

    .full-message-item-inner {
      @include align-center(20px);

      width: 100%;
    }

    .message-date-text {
      font-size: 11px;

      span:first-child {
        padding-right: 4px;
      }

      span:last-child {
        padding-left: 4px;
      }
    }

    .message-container {
      background-color: #f2f2f2;
      padding: 12px 20px;
      line-height: 20px;
      position: relative;
      color: var(--secondaryTextColor);
      margin-bottom: 3px;
      border: 1px solid #f2f2f2;
      flex-grow: 1;

      p {
        margin-bottom: 8px;

        &.message-recall-time {
          font-size: 11px;
          line-height: 13px;

          span:first-child {
            padding-right: 4px;
          }

          span:last-child {
            padding-left: 4px;
          }
        }
      }

      .message-text {
        span span {
          background-color: transparent !important;
          border-bottom: none !important;
        }

        .zxps-0 {
          background: var(--servity-0-highlight);
          border-bottom: 1px solid var(--servity-0-color);
        }

        .zxps-1 {
          background: var(--servity-1-highlight);
          border-bottom: 1px solid var(--servity-1-color);
        }

        .zxps-2 {
          background: var(--servity-2-highlight);
          border-bottom: 1px solid var(--servity-2-color);
        }

        .zxps-3 {
          background-color: var(--servity-3-highlight);
          border-bottom: 1px solid var(--servity-3-color);
        }

        .zxps-4 {
          background: var(--servity-4-highlight);
          border-bottom: 1px solid var(--servity-4-color);
        }
      }

      &.message-recalled {
        .message-text {
          color: var(--disabledTextColor);
          font-style: italic;

          i-feather {
            color: var(--secondaryTextColor);
            margin-right: 4px;
          }
        }

        .attachment-icons-item {
          img {
            opacity: 0.3;
          }

          i-feather {
            color: var(--disabledTextColor);
          }
        }
      }

      &::before {
        content: '';
        border-style: solid;
        border-width: 7px 10px 7px 0;
        border-color: transparent #f2f2f2 transparent transparent;
        position: absolute;
        left: -10px;
        top: calc(50% - 7px);
      }
    }

    &.right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .full-message-item-inner {
        flex-direction: row-reverse;
      }

      .full-message-content-inner {
        flex-direction: row-reverse;
      }

      .message-date-text {
        margin-left: 24px;
        background-color: transparent;
      }

      .message-container {
        background-color: var(--fgColor);
        border-color: var(--separatorColor);

        &::before {
          border-color: transparent transparent transparent var(--separatorColor);
          left: initial;
          border-width: 8px 0 9px 11px;
          right: -11px;
          top: calc(50% - 9px);
        }

        &::after {
          content: '';
          border-style: solid;
          border-width: 7px 0 7px 10px;
          border-color: transparent transparent transparent #fff;
          position: absolute;
          right: -9px;
          top: calc(50% - 7px);
        }
      }
    }
  }

  .ngb-msg-dropdown {
    box-shadow: var(--card-box-shadow);

    .dropdown-item {
      @include body-text;
      @include align-center;

      border-radius: 0;
      padding: 5px 10px 5px 20px;
      gap: 12px;

      &:focus {
        border: none;
      }

      .ngb-dropdown-text {
        display: inline-block;
        padding-left: 32px;
      }
    }
  }
}

.sidebar-msg-body {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 4px;
  gap: 8px;

  .sidebar-msg-icons {
    display: flex;
    gap: 8px;
  }
}

.sidebar-msg-text {
  @include line-clamp(1, hidden);

  font-size: var(--small-font-size);
}

.message-item-date {
  font-size: 11px;
  text-align: right;
  white-space: nowrap;
}

.title-date {
  gap: 4px;
}

.message-info {
  @include align-center(8px);
}

button.broadcast {
  font-size: var(--small-font-size);
  padding: 1px 5px;
  border-radius: 47px;
}

.message-item-text {
  flex-grow: 1;
  max-width: calc(100% - 45px);
}

.message-item-title {
  @include ellipsis();

  font-family: font-bold, sans-serif;
}

/* --- new message dialog ajdustment --- */

.new-message-container {
  .message-template {
    mat-dialog-content {
      max-height: calc(65vh - 80px);
    }
  }

  app-patient-summarization {
    .patient-summary-container {
      width: auto;
      max-height: calc(65vh - 80px);
    }
  }
}

/* --- Message Template --- */

.template-group {
  padding-bottom: 5px;
}

.message-h6 {
  @include sm-text;

  font-family: 'font-bold', sans-serif;
  margin: 16px 0 13px;
  color: var(--secondaryTextColor);
}

.user-image {
  @include size(37px);

  min-width: 37px;
  border-radius: 50%;
  overflow: hidden;
}

.template-radio {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 18px;

  .radio-label {
    display: flex;
    flex-direction: column;
    margin-block: 0;
    font-size: 10px;
  }
}

.msg-twocol {
  .message-template {
    mat-dialog-content {
      max-height: none;
    }
  }
}

/* --- Message Tooltip --- */

.message-tooltip {
  text-align: left;
  padding-inline: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .message-tooltip-title {
    font-family: font-medium, sans-serif;
    margin-bottom: 0;
  }

  .alert-item {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  .alert-bullet {
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--fgColor);
  }
}

.chat-favourite {
  color: var(--linkTextColor);
  fill: var(--linkTextColor) !important;
}

.message-dialog .mat-mdc-dialog-container {
  overflow-y: hidden !important;
  height: 98dvh !important;
  max-height: none !important;

  .mdc-dialog__surface{
    position: static;
  }
}

.suggested-message-text {
  height: 52px;
  margin-bottom: 12px;
}

app-message-dialog-placeholder {
  height: 100%;
  display: block;
}

app-message {
  display: block;
  height: 100%;
}

.message-left-col {
  height: 100%;
  min-width: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
}

.message-right-col {
  height: 100%;
}

.message-detail-area {
  display: grid;
  grid-template-rows: auto minmax(25px, 1fr) auto;
  height: 100%;

  &.my-team {
    height: calc(100% - 30px);
  }
}

.beta-badge {
  margin-left: 10px;
}

/* message filter */

.message-autocomplete.mat-mdc-autocomplete-panel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block: 8px;

  .mat-mdc-option {
    overflow: initial !important;
    background-color: transparent !important;
    margin: 0;
    height: auto;
  }
}

/* slide down animation start */

.slide-down-base {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.slide-down-animation {
  opacity: 1;
  height: 100%;
  transition: all 1s ease-in-out;
}

/* slide down animation end */

@include media('up', lg) {
  .message-wrapper {
    .message-with-summary .full-message-item .full-message-content {
      width: 80%;
    }
  }
}

@include media('down', xl) {
  .message-wrapper {
    .full-message-item {
      .full-message-content {
        width: 80%;
      }
    }

    .message-list {
      .message-item {
        padding: 12px;
        gap: 12px;
      }
    }

    .message-details {
      .message-footer {
        .message-footer-main {
          gap: 10px;
        }
      }
    }
  }

  .message-left-col {
    min-width: 300px;
    width: 300px;
  }

  .message-result {
    width: 100%;
  }

  .message-detail-area {
    height: calc(100% - 40px);
  }
}

/* for tab - below 992px */
@include media('down', lg) {
  .hide-section {
    display: none !important;
  }

  .hs-tab-card-title.view-below-tab,
  .patient-summary-title.view-below-tab {
    display: flex !important;
  }

  .message-wrapper {
    flex-direction: column;
    height: calc(100% - 40px);

    .message-list {
      .message-item {
        padding-inline: 10px;
      }

      .message-item-text {
        width: calc(100% - 57px);
      }

      .sidebar-msg-text {
        width: calc(100% - 70px);
      }
    }

    .full-message-item {
      &.right .message-date-text {
        width: auto;
        padding-right: 57px;
      }

      .full-message-item-inner {
        width: 100%;
      }

      .full-message-content {
        width: calc(100% - 55px);
      }

      .message-container {
        width: calc(100% - 25px);
        max-width: none;
      }
    }

    .message-details {
      display: none;
      width: 100%;
      min-width: 0;

      &.show-section {
        display: block;
        padding: 0;
        border: none;
      }

      .message-detail-area.my-team {
        .message-history {
          height: calc(100% - 85px);
        }
      }

      .message-footer {
        .message-footer-main {
          justify-content: space-around;
          align-items: center;
        }
      }
    }
  }

  .message-left-col {
    min-width: 0;
    width: 100%;
  }

  .message-right-col {
    display: none;

    &.show-section {
      display: block;
    }
  }

  .leave-conversation {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .patient-summary-title {
    margin-bottom: 0;

    .patient-summary-title-text {
      @include ellipsis();
    }
  }

  .mobile-patient-summary {
    .patient-summary-title {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      &-text {
        @include ellipsis();
      }
    }
  }
}

/* mobile version */

@include media('down', md) {
  .leave-conversation {
    top: 12px;
  }

  .message-wrapper .message-details {
    height: calc(100% - 12px);
  }

  .mobile-patient-summary {
    .patient-summary-title {
      position: absolute;
      top: 11px;
      left: 12px;
    }
  }

  .full-screen-dialog .dialog-sm {
    width: 100% !important;
  }

  .message-right-col {
    height: auto;

    &:has(.mobile-patient-summary) {
      height: 100%;
    }
  }
}

@include media('down', md) {
  .message-footer {
    overflow-y: auto;
  }
}
