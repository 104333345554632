gridster {
  @include scrollbar;

  gridster-item {
    background-color: transparent;

    .card-base {
      height: 100%;
      overflow-y: auto;
    }
  }
}

md-tabs {
  gridster-item {
    padding: 3px;

    .table-responsive {
      --table-font-size: 12px;
      --table-line-height: 14px;
    }
  }
}

@include media('between', md, xl) {
  gridster {
    display: flex !important;
    flex-wrap: wrap;
    gap: 5px;
    flex-direction: row;

    gridster-item {
      position: static;
      flex-grow: 1;
      flex-basis: 30%;
      width: auto !important;
      display: flex !important;
      height: auto !important;
      overflow: initial !important;
      max-width: 100% !important;
      max-height: 470px;

      app-widgetswrapper {
        width: 100%;
      }
    }
  }
}

@include media('down', md) {
  gridster-item {
    .display-2 {
      font-size: 35px;
    }
  }
}
