/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

/* Bootstrap mixin */

/*  @import '~bootstrap/scss/bootstrap'; */
@import './bootstrap/bootstrap.scss';

/* base */
@import './base/mixin';
@import './base/typography';

/* utilities */
@import './utilities/helpers';
@import './utilities/global';
@import './utilities/colors';
@import './utilities/sizing';

/* pages */
@import './pages/authentication';
@import './pages/dashboard-widget';
@import './pages/health-summary';
@import './pages/vitals';
@import './pages/message';
@import './pages/settings';
@import './pages/communication';
@import './pages/calendar';
@import './pages/users';
@import './pages/communication-calendar';
@import './pages/video-lobby';
@import './pages/video-lobby-widget';
@import './pages/tech-support';
@import './pages/guided-session';

/* partials */
@import './partials/miscellaneous';
@import './partials/pagination';
@import './partials/table';
@import './partials/modal';
@import './partials/cards';
@import './partials/tooltip';
@import './partials/mat-tab';
@import './partials/mat-menu';
@import './partials/gridster';
@import './partials/ck-editor';
@import './partials/editable';
@import './partials/header';
@import './partials/json-viewer';
@import './partials/expansion-panel';
@import './partials/reach';

/* form */
@import './form/buttons';
@import './form/form';
@import './form/mat-input';

/* responsive */
@import './responsive';

* {
  outline: none;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  @include body-text();

  letter-spacing: 0.005em;
  color: var(--primaryTextColor);
  background-color: var(--bgColor);
}

.container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}

.container {
  margin: auto !important;
  padding: 20px 0;
}

.toast-container {
  z-index: 9999;
}

.servity-0 {
  background-color: var(--servity-0-color);
}

.servity-1 {
  background-color: var(--servity-1-color);
}

.servity-2 {
  background-color: var(--servity-2-color);
}

.servity-3 {
  background-color: var(--servity-3-color);
}

.servity-4 {
  background-color: var(--servity-4-color);
}

.hide-class .ck-sticky-panel {
  display: none !important;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.disabled {
  .action-icons {
    .feather-20 {
      pointer-events: initial;

      svg {
        stroke: var(--primaryTextColor);
      }
    }
  }
}

.noResult {
  background: var(--fgColor);
  padding: 15px 10px;
  text-align: center;
  color: var(--disabledTextColor);
}
