.mat-norecord {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  text-align: center;
  letter-spacing: 0.0025em;
  margin-top: 40px;
  color: var(--secondaryTextColor);
}

/* ---mat select--- */

.mat-form-field-underline {
  display: none;
}

.mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  @include size(15px);

  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid;
  margin: 0 4px;
  background: no-repeat center/70% url('../../../assets/images/icons/gray-angle-down-solid.svg');

  svg {
    display: none;
  }
}

.mtable .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  background: no-repeat center/70% url('../../../assets/images/icons/white-angle-down-solid.svg');
}

/* --- active-badge --- */

.active-badge,
.inactive-badge {
  @include size(10px);

  border: 2px solid #fff;
  background-color: var(--positiveColor);
  position: absolute;
  border-radius: 50%;
  min-width: 10px;
  min-height: 10px;
  inset: -2px 0 auto auto;
  display: inline-block;
}

.inactive-badge {
  background-color: var(--negativeColor);
}

.dot-badge {
  position: relative;
  margin-right: 8px;

  .inactive-badge {
    inset: -2px 10px 0;
  }
}

/* --- toast --- */

.toast-container {
  z-index: 3;
}

/* ---  mat slider for volume --- */
.mat-mdc-slider.mat-accent .mat-mdc-slider-track-fill,
.mat-mdc-slider.mat-accent .mat-mdc-slider-thumb,
.mat-mdc-slider.mat-accent .mat-mdc-slider-thumb-label {
  background-color: var(--primaryColor) !important;
}
