.clr-primary {
  color: var(--primaryTextColor);
}

.clr-secondary {
  color: var(--secondaryTextColor);
}

.clr-disabled {
  color: var(--disabledTextColor);
}

.clr-link {
  color: var(--linkTextColor);
}

.clr-default {
  color: var(--primaryColor);
}

.clr-complete,
.success {
  color: var(--positiveColor);
}

.clr-processing {
  color: var(--secondaryTextColor);
}

.clr-fail,
.invalid {
  color: var(--negativeColor);
}

.bg-orange{
  background-color: #FF7B2E;
}

.bg-yellow{
  background-color: #F3C300;
}

.bg-lightOrange {
  background-color: rgba(#ff7b2e, 0.05);
}

.bg-lightBlue {
  background-color: rgba(#3c66ff, 0.05);
}

.bg-lightGreen {
  background-color: rgba(#34bb6f, 0.1);
}

.bg-lightRed {
  background-color: rgba(255, 123, 46, 0.05);
}

.bg-lightVoilet {
  background-color: rgba(60, 102, 255, 0.05);
}

i-feather {
  &.status-enrolled {
    color: var(--positiveColor);
  }

  &.status-preferred {
    color: rgba(153, 202, 49, 1);
  }

  &.status-partial-qualified {
    color: #4A94D9;
  }

  &.status-invited {
    color: var(--primaryColor);
  }

  &.status-not-invited {
    color: var(--smoke-gray);
  }

  &.status-declined {
    color: var(--smoke-gray);
  }

  &.status-screen-pass {
    background-image: url(../../images/icons/screen-pass.svg);
    color: transparent;
  }

  &.status-screen-fail {
    background-image: url(../../images/icons/screen-fail.svg);
    color: transparent;
  }

  &.check-positive {
    color: var(--positiveColor);
  }

  &.x-negative {
    color: var(--negativeColor);
  }
}