.dashboard-dataTable {
  height: calc(100% - 28px);

  table {
    tr.mat-mdc-row,
    tr.mat-mdc-footer-row {
      height: 64px;
    }

    tr {
      td {
        .progress-rate {
          margin-left: 8px;
          margin-right: 12px;
        }

        .action-buttons {
          gap: 16px;

          .action-icon {
            @include size(20px);

            cursor: pointer;
          }
        }
      }

      td.mat-column-patientFullName {
        cursor: pointer;
      }

      .filter-status-badge {
        inset: -1px 0 auto auto;
      }

      .user-groups {
        .more-link {
          color: var(--primaryColor);
          cursor: pointer;
        }
      }

      .profile-cell {
        @include align-center;

        color: var(--primaryColor);

        img {
          @include size(32px);

          border-radius: 50%;
          object-fit: contain;
          margin-right: 8px;
          opacity: 1;
        }

        .status-badge {
          inset: auto 6px -2px auto;
        }
      }
    }
  }
}

.multicolor-progress-bar {
  width: 138px;
  background-color: var(--light-silver);
  border-radius: 30px;
  overflow: hidden;
  height: 10px;
  display: flex;

  .progress-item-accepted {
    background-color: var(--positiveColor);
  }

  .progress-item-denied {
    background-color: var(--negativeColor);
  }
}

.patient-list-card {
  display: flex;
  flex-direction: column;

  /* margin: 3px 3px 0; */
}

.criteria-control-icons {
  @include align-center(5px);

  i-feather {
    @include size(18px);

    cursor: pointer;
  }
}

.reach-status {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  i-feather {
    @include size(16px);
  }
}

#patient-by-status-chart-column * {
  max-width: 100%;
}

.highcharts-root {
  font-family: var(--regular) !important;
}
