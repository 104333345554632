/* search and filter dialog */

.search_dialog,
.filter_dialog {
  .mat-mdc-dialog-content {
    max-height: calc(65vh - 250px);
  }
}

.dialog-header {
  border-bottom: 1px solid var(--separatorColor);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.mat-mdc-dialog-container {
  min-width: 320px !important;

  .mdc-dialog__container {
    .mdc-dialog__content {
      @include body-text();

      color: var(--primaryTextColor);
      letter-spacing: 0.005em;
    }

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      box-shadow: var(--box-shadow) !important;
      max-height: 90vh !important;
      border-radius: 16px !important;
      padding: 20px 16px !important;
    }

    .hs-tab-card-title {
      padding-inline: 4px;
      align-items: flex-start;
    }

    .mat-mdc-dialog-content {
      margin: 0 -16px;
      padding: 4px 16px;
      -webkit-overflow-scrolling: touch;
    }

    .mat-mdc-dialog-actions {
      margin-bottom: 0;
      padding-bottom: 0;
      box-sizing: border-box;

      .btn-group.btn-center {
        flex-grow: 1;
        padding-block: 0;
      }
    }

    .dialog-xl {
      width: 1500px;
      max-width: 100%;
    }

    .dialog-lg {
      width: 1000px;
      max-width: 100%;
    }

    .dialog-md {
      width: 800px;
      max-width: 100%;
    }

    .dialog-sm {
      width: 600px;
      max-width: 100%;
    }

    .dialog-xs {
      width: 320px;
      max-width: 100%;
    }
  }
}

.cdk-overlay-container {
  z-index: 1002;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.25);
}

.term-details-modal {
  width: 650px;

  @include scrollbar;

  .mat-mdc-dialog-container {
    padding: 40px 26px 16px 32px !important;
  }

  .terms-modal-content {
    max-height: calc(90vh - 125px);
    padding-right: 6px;
    overflow: auto;
  }
}

.encounter_expand {
  .list-col-2 {
    display: inline-grid;
    margin-top: 0 !important;
    width: 100%;
  }
}

.allergy_modal_class .mat-mdc-dialog-container {
  width: 400px;
  overflow: hidden;
}

.status-filter {
  .mat-mdc-dialog-container {
    min-width: 250px !important;
  }
}

/* --- --- */

.cdk-overlay-pane {
  max-width: 90vw !important;

  @include media('up', md) {
    &.message-dialog,
    &.video-lobby-dialog {
      max-width: 98vw !important;
    }
  }

  @include media('down', md) {
    &.message-dialog,
    &.video-lobby-dialog {
      max-width: 100dvw !important;
    }
  }
}

.header-filter-dialog.cdk-overlay-pane {
  .mat-mdc-dialog-container {
    min-width: 264px !important;
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
    width: 235px;
  }
}

/* --- for calendar popup accordian--- */
.calendar-body {
  .mat-mdc-expansion-panel-content {
    .mat-mdc-expansion-panel-body {
      padding: 0 16px 16px !important;
      font-family: font-regular, sans-serif;
    }
  }

  .mat-mdc-slide-toggle-label {
    margin: 0 !important;
  }
}

/* --- for calendar popup accordian--- */

/* --- filter count dialog--- */
.count-filter {
  font-size: var(--small-font-size);
  background-color: var(--primaryColor);
  color: var(--textColorOnPrimary);
  padding: 2px 10px;
  border-radius: 50px;
}

/* --- end dialog --- */

.mat-mdc-dialog-container .mat-costum.mat-mdc-dialog-content {
  padding: 0;
}

body:has(.full-calendar-dialog) {
  position: static;
  overflow-y: hidden;
}

@include media('down', md) {
  .mat-mdc-dialog-container {
    .dialog-sm {
      width: 100%;
    }

    .hs-tab-card-title {
      padding-inline: 0;
    }
  }
}

@include media('down', sm) {
  .mat-mdc-dialog-container {
    min-width: 280px !important;
  }
}
