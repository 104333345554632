/* dashboard document component paginator */
.mat-mdc-paginator-container {
  min-height: auto !important;

  .mat-mdc-paginator-icon {
    fill: var(--secondaryTextColor);
  }
}

.mat-mdc-paginator-range-label {
  @include body-text;

  color: var(--secondaryTextColor);
}

.docs-paginator {
  .mat-mdc-paginator-container {
    padding-inline: 0;
  }

  .mat-mdc-icon-button {
    width: 20px;
  }

  .mat-mdc-paginator-range-actions {
    margin-top: 0;
  }

  .mat-mdc-paginator-range-label {
    margin-inline: 5px;
  }
}

/* paginator */

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-select-trigger {
  @include body-text;
}

.mat-mdc-paginator {
  background-color: transparent !important;
  padding-top: 10px;

  .mat-mdc-select {
    @extend .form-control;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-infix {
      color: var(--textColorOnPrimary);
      background-color: var(--primaryColor);
      padding-inline: 0 !important;
      padding: 0.2em 2px !important;
      min-height: auto;
      border-radius: 8px;
      border-top: 0 solid transparent;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);

      .mat-mdc-select-min-line {
        color: var(--textColorOnPrimary);
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
      border: none !important;
    }

    .mat-mdc-form-field-flex {
      border-radius: 0;
      padding: 0;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(0);
    }
  }

  .mat-mdc-paginator-page-size-select {
    margin: 6px 4px 0 4px;
    width: 56px;
  }

  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    background: no-repeat center/70% url('../../images/icons/white-angle-down-solid.svg');
  }
}

.mtable .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  background: no-repeat center/70% url('../../images/icons/gray-angle-down-solid.svg');
}

.mat-mdc-paginator-page-size {
  @include body-text;

  color: var(--secondaryTextColor) !important;
}

.mat-mdc-select-panel {
  .mdc-list-item__primary-text,
  .mat-mdc-option {
    font-size: var(--body-font-size) !important;
    line-height: var(--body-line-height) !important;
    font-family: font-regular, sans-serif !important;
    margin-right: 0 !important;
  }
}

.mat-mdc-paginator-outer-container .mat-mdc-select {
  background-color: transparent !important;
  padding: 0 0 0 10px;
  box-shadow: none;
  border: 0;
  height: auto;

  &:focus {
    box-shadow: none;
  }

  .mat-mdc-select-value {
    color: var(--textColorOnPrimary);
  }

  .mat-mdc-select-panel {
    border-radius: inherit;
    margin-top: initial;
    width: auto !important;
  }
}

@include media('down', sm) {
  .mat-mdc-paginator-container {
    padding-inline: 0 !important;
    justify-content: space-between !important;

    .mat-mdc-paginator-page-size-label {
      display: none;
    }

    .mat-mdc-paginator-range-label {
      margin-inline: 0 20px;
    }

    .mat-mdc-paginator-page-size-select {
      margin-inline: 0;
    }

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .mat-mdc-icon-button {
      width: 32px;
    }
  }
}
