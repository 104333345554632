.breadcrumb {
  font-family: var(--medium);
  letter-spacing: 0.004em;
  color: var(--linkTextColor);
  flex-wrap: nowrap;
  overflow-y: auto;

  .breadcrumb-item {
    min-width: fit-content;

    & + .breadcrumb-item::before {
      content: '>';
      color: var(--primaryTextColor);
    }

    &.active {
      color: var(--primaryTextColor);
    }
  }
}

.managed_accordion span {
  display: inline-block !important;
}

.user-status {
  .preferences-title-bar {
    @include flex(null, space-between);
  }
}

.col-providers {
  .col-pvdr-row {
    @include flex(null, space-between);

    padding-bottom: 24px;
  }

  .pvdr-left {
    @include flex(null, center, center);
  }

  .p-icon-img {
    @include flex(null, center, center);

    width: 30px;
    margin-right: 20px;
  }
}

.user-groups {
  .user-group-item-title {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  label {
    margin-bottom: 0;
  }
}

.user-count .feather-20 {
  color: var(--secondaryTextColor);
}

.managed_support .mat-expansion-panel-body {
  padding: 0 0 0 24px;
}

.checkbox-2-col {
  display: flex;
  flex-wrap: wrap;

  .form-check,
  .form-check-flex,
  .list-inline {
    flex-basis: 50%;
    max-width: 50%;
  }

  .form-group + .form-group {
    margin-top: 0;
  }

  .form-group:last-child {
    margin-bottom: 1.1rem;
  }
}

.user-status-preferences {
  h2 {
    margin-bottom: 0;
  }
}

.action-enabled .disabled {
  pointer-events: initial;

  &.mat-mdc-row:hover {
    background-color: transparent;
  }

  * {
    pointer-events: none;
    color: var(--disabledTextColor);
  }

  .mat-column-Actions i-feather * {
    pointer-events: initial;
    color: var(--secondaryTextColor);
  }
}

.user-card-header {
  margin-bottom: 24px;
}
 
@include media('up', md) {
  .user-group-view {
    .mat-mdc-tab-body-wrapper,
    .mat-mdc-tab-body,
    .mat-mdc-tab-body.mat-mdc-tab-body-active,
    .mat-mdc-tab-body-content {
      overflow: visible !important; /* overflow:visible is important to use for default user group button */
    }
  }

  .user-status {
    .mat-expansion-indicator::after {
      padding: 4px;
    }
  }
}

@include media('down', md) {
  .checkbox-2-col {
    .form-check,
    .form-check-flex,
    .list-inline {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .user-card-header {
    margin-bottom: 12px;

    h2 {
      @include ellipsis();
    }
  }
}
