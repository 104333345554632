@mixin ckEditorOverrideFormControl {
  /* if form-control class added to ck-editor and editor is without edit-option-header */
  background-color: transparent !important;
  box-shadow: none !important;
  white-space: pre-line;
  padding-block: 8px;
  border-radius: 8px !important;
}

@mixin ckEditorOverride {
  background-color: var(--txtbox-enabled) !important;
  color: var(--primaryTextColor);
  font-size: var(--txtbox-font-size);
  line-height: var(--txtbox-line-height);
  font-family: var(--txtbox-font-family);
  border-radius: 0 0 8px 8px !important;
}

@mixin ckEditorStickyPanel($display) {
  .ck.ck-sticky-panel__content {
    display: $display;
  }
}

@mixin ckEditorPSpacing($margin) {
  p {
    margin-block: $margin !important;
  }
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: var(--lightBgColor);
  border: 0;
}

.ck-button__label {
  font-family: var(--medium) !important;
  color: var(--secondaryTextColor) !important;
}

.message-ck-editor {
  @include ckEditorStickyPanel(none);
  @include ckEditorPSpacing(3px);

  .ck-editor__editable_inline {
    max-height: 45px;
  }

  &.editor-with-reset {
    .ck-editor__editable_inline {
      max-height: 117px;
    }
  }
}

.message-thread-ck-editor {
  @include ckEditorStickyPanel(none);

  max-width: none !important;
  width: 100% !important;

  @include ckEditorPSpacing(3px);

  .ck-editor__editable_inline {
    max-width: 100%;
    width: 100%;
    height: 87px;
    max-height: 150px;
  }
}

.form-control.message-ck-editor,
.form-control.message-thread-ck-editor {
  border: none;
  padding: 0;

  .ck.ck-editor__main {
    .ck-editor__editable_inline {
      border-color: transparent !important;
      padding: 0.5rem 0.5rem;
      border-radius: 8px !important;
      box-shadow:
        0 -1px 1px var(--txtbox-border) inset,
        0 1px 0 #fff;

      &.ck-focused {
        border-color: var(--primaryColor) !important;
        box-shadow: 0 0 0 0.2rem rgb(34 107 252 / 25%) !important;
      }
    }
  }
}

.ck.ck-editor__main {
  .ck-editor__editable_inline {
    @include ckEditorOverride();

    &.ck-focused {
      border-color: var(--primaryColor) !important;
      box-shadow: 0 0 0 0.2rem rgb(34 107 252 / 25%) !important;
    }
  }
}

.ck-editor.form-control {
  @include ckEditorStickyPanel(none);

  border: none;
  padding: 0;

  .ck.ck-editor__main {
    .ck-editor__editable_inline {
      @include ckEditorOverrideFormControl();

      min-height: 65px;
      padding-block: 4px;

      &.ck-focused {
        border-color: var(--primaryColor) !important;
        box-shadow: 0 0 0 0.2rem rgb(34 107 252 / 25%) !important;
      }
    }
  }
}

.ck-editor-base-height {
  .ck-editor__editable_inline {
    height: 200px;
    max-height: 200px;
  }
}
