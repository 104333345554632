@import '../base/mixin';

.mat-mdc-row,
.mdc-data-table__content {
  font-family: var(--table-font-family) !important;
}

.table-AI {
  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    font-size: var(--table-font-size);
    min-width: 140px;
    max-width: 95px;
    padding-right: 14px;
  }
}

.prov-table-size,
.customGroups {
  th.mat-mdc-header-cell {
    min-width: 180px;
    max-width: 450px;
  }

  th.mat-column-Actions {
    min-width: 80px;
  }
}

.customGroups {
  .mat-column-Name .head-in {
    font-family: var(--bold);
  }
}

.table-responsive {
  .mat-mdc-table {
    background-color: var(--table-background);
    font-family: var(--table-font-family);
    border: 1px solid var(--separatorColor);
    width: 100%;
  }

  tr.mat-mdc-row {
    &:hover {
      background-color: var(--table-row-hover);
    }
  }

  th.mat-mdc-header-cell {
    position: relative;
    background-color: var(--table-header-background);
    color: var(--table-header-textColor);
    font-family: var(--table-header-font-family);
    border-bottom-color: var(--separatorColor);
    font-weight: 400;
    padding-right: 40px;

    &:not(:has(.tbl-sort)) {
      padding-right: 16px;
    }

    & > span.position-relative {
      margin-left: 8px;
    }
  }

  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    font-family: var(--table-font-family);
    border-bottom-width: 0;
    color: var(--table-textColor);

    &.clr-primary {
      color: var(--primaryTextColor);
    }
  }

  // .mat-mdc-header-cell {
  //   position: relative;
  //   font-family: font-medium, sans-serif;
  //   font-weight: 400;
  //   line-height: 16px;
  // }

  .tbl-sort i-feather {
    height: 10px;
    cursor: pointer;
  }

  // .mat-mdc-cell,
  // .mat-mdc-footer-cell {
  //   color: var(--table-textColor);

  //   &.clr-primary {
  //     color: var(--primaryTextColor);
  //   }
  // }

  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    font-size: var(--table-font-size);
    line-height: var(--table-line-height);
    padding: 16px;
    border-right-color: var(--separatorColor);
    border-right-width: 1px;

    &:last-child {
      border-right-width: 0;
    }
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 16px;
  }

  mat-paginator {
    border-top: 1px solid transparent;
    padding-top: 10px;
  }

  .tbl-sort {
    @include flex-center(column);

    position: absolute;
    right: 16px;
    top: calc(50% - 15px);

    i-feather.active {
      stroke-width: 4px;
    }
  }

  .active-cell {
    background: var(--table-header-sorted-background) !important;

    .active-sort {
      stroke-width: 4px;
    }
  }

  .mat-focus-indicator {
    position: static;
    border-bottom: 0 !important;
  }

  .filter-status-badge {
    @include size(8px);

    min-width: 8px;
    min-height: 8px;
    border-width: 1px;
  }
}

.badge-table {
  position: relative;
  display: inline-block;
}

.badge {
  padding: 0.1em 0.3em;
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(-50%, -50%) !important;
  background-color: var(--negativeColor);
}

.w-date {
  width: 110px;
}

.priority-btn {
  padding: 1px 12px;
  border-radius: 47px;
  font-size: var(--body-font-size);
  letter-spacing: 0.0025em;
  font-family: font-bold, sans-serif;
  color: var(--whiteTextColor);
  line-height: 18px;
}

.table-actions {
  width: 140px;
}

th.mat-mdc-header-cell.center {
  text-align: center;
}

.table-AI {
  .table-responsive th.mat-mdc-header-cell:first-of-type,
  .table-responsive td.mat-mdc-cell:first-of-type,
  .table-responsive td.mat-mdc-footer-cell:first-of-type {
    min-width: 294px;
  }

  .example-detail-row {
    display: none;
  }

  .example-expanded-row + .example-detail-row {
    display: table-row;
  }

  .mat-column-expandedDetail {
    padding: 0 !important;

    .mat-mdc-table {
      border: none;
    }

    .mat-mdc-table thead {
      display: none;
    }
  }
}

.inner-table {
  td {
    .first-col {
      span {
        display: block;
        padding-top: 5px;
      }
    }
  }
}

.cell-reset {
  ul {
    margin: 0 0 5px;
    padding: 0 0 0 16px;
  }
}

.action-icons {
  display: flex;
  gap: 15px;
}

.search-filter {
  margin-left: 12px;
  position: relative;
}

.toggle-cell {
  text-align: center;
}

@include media('down', xl) {
  .table-responsive {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      min-width: 175px;
    }
  }
}

@include media('down', md) {
  .table-actions {
    width: auto;
  }

  .action-icons {
    gap: 12px;
  }

  .table-responsive {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      min-width: 200px;

      &.mat-column-Actions,
      &.mat-column-actions,
      &.toggle-cell,
      &.toggle-cell-width {
        min-width: 100px;
      }
    }

    .tbl-sort {
      top: calc(50% - 12px);
    }

    .icon-flex {
      gap: 12px;
    }
  }
}
