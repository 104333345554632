.btn {
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
}

.btn-primary {
  background-color: var(--btn-primaryColor);
  color: var(--btn-primaryTextColor);
  border-width: 0 0 2px 0;
  border-color: var(--btn-primaryBorder);

  &:hover,
  &:focus-visible {
    background-color: var(--btn-primaryHoverColor);
    color: var(--btn-primaryHoverTextColor);
    border-color: var(--btn-primaryBorder);
  }

  &:active {
    background-color: var(--btn-primaryHoverColor) !important;
    border-color: var(--btn-primaryBorder) !important;
  }

  &:disabled {
    background-color: var(--buttonDisable);
    color: var(--disabledTextColor);
    box-shadow: none;
    border: none;
    opacity: 1;
  }
}

.btn-outline-primary {
  background-color: transparent;
  border-color: var(--btn-primaryColor);
  color: var(--btn-primaryColor);

  &:hover,
  &:focus,
  &.active {
    background-color: var(--btn-primaryColor) !important;
    border-color: var(--btn-primaryColor) !important;
    color: var(--btn-primaryTextColor) !important;
  }
}

.btn-secondary {
  background-color: var(--btn-secondaryColor);
  color: var(--btn-secondaryTextColor);
  border-width: 0 0 2px 0;
  border-color: var(--btn-secondaryBorder);

  &:hover,
  &:focus-visible {
    background-color: var(--btn-secondaryHoverColor);
    color: var(--btn-secondaryHoverTextColor);
    border-color: var(--btn-secondaryBorder);
  }

  &:active {
    background-color: var(--btn-secondaryHoverColor) !important;
    color: var(--btn-secondaryHoverTextColor) !important;
    border-color: var(--btn-secondaryBorder) !important;
  }

  &:disabled {
    background-color: transparent;
    color: var(--disabledTextColor);
    box-shadow: none;
    border: none;
    opacity: 1;
  }
}

// .btn-link {
//   color: var(--primaryTextColor);

//   &:hover {
//     color: var(--primaryTextColor) !important;
//   }

//   &:disabled {
//     color: var(--disabledTextColor) !important;
//     opacity: 1;
//   }
// }

/* #######   Button group   ####### */

.btn-group {
  > .btn:not(:first-child) {
    margin-left: 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.btn-center {
    @include flex-center;

    gap: 16px;
    padding-block: 8px;

    .btn {
      flex: initial;
    }
  }

  > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.btn-disable {
  color: var(--disabledTextColor) !important;
  background-color: var(--buttonDisable) !important;
  border-color: var(--buttonDisable) !important;
  pointer-events: none;
}

.btn-plus-minus {
  padding: 0;

  @include size(27px);
}

/*  time-period-btn for health summary modals */

.time-period-btn {
  display: flex;
  gap: 16px;
  padding-right: 16px;
  flex-wrap: wrap;
  justify-content: flex-end;

  button {
    width: 64px;
    height: 27px;
    padding: 5px;

    @include flex-center;
  }
}

.btn-sm-varient {
  @extend .time-period-btn;
}

.btn-flex {
  @include align-center(8px);
}

@include media('down', md) {
  .time-period-btn {
    width: 100%;
    gap: 12px;
    padding-right: 0;

    button {
      flex-grow: 1;
      width: auto;
      padding-inline: 8px;
    }
  }
}
