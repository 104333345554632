@import 'src/assets/scss/base/mixin';

@font-face {
  font-family: font-bold;
  src: url(../../fonts/Ubuntu-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: font-medium;
  src: url(../../fonts/Ubuntu-Medium.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: font-regular;
  src: url(../../fonts/Ubuntu-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* Default Color Variables */
  --primaryColor: #5166b1;
  --textColorOnPrimary: #fff;
  --positiveColor: #34bb6f;
  --warningColor: #f3c300;
  --negativeColor: #e71d36;
  --primaryColorOpacity-07: rgba(81, 102, 177, 0.07);
  --primaryColorOpacity-15: rgba(81, 102, 177, 0.15);
  --primaryColorOpacity-20: rgba(81, 102, 177, 0.2);

  /* 
  --secondaryColor: #6c757d;
  --textColorOnSecondary: rgba(0, 0, 0, 0.85);
  --accentColor: #946449;
  --textColorOnAccent: #fff; 
  */

  /* Core Color Variables */
  --bgColor: #eef0f8;
  --fgColor: #fff;
  --separatorColor: #ddd;
  --coreGrayBackground: #f6f6f6;
  --defaultGraySecondary: #eaeaea;
  --tooltipColor: #2c2c2c;
  --textColorInTooltip: #fff;

  /* Text Color Variables */
  --primaryTextColor: rgba(0, 0, 0, 0.85);
  --secondaryTextColor: rgba(0, 0, 0, 0.64);
  --disabledTextColor: rgba(0, 0, 0, 0.32);
  --linkTextColor: #226bfc;
  --whiteTextColor: #fff;

  /* Text Size and Line Height Variables */
  --h1-font-size: 24px;
  --h1-line-height: 32px;
  --h2-font-size: 20px;
  --h2-line-height: 23px;
  --h3-font-size: 16px;
  --h3-line-height: 18px;
  --body-font-size: 14px;
  --body-line-height: 16px;
  --small-font-size: 12px;
  --small-line-height: 14px;

  @include media('down', md) {
    --h1-font-size: 22px;
    --h1-line-height: 1.2;
    --h2-font-size: 18px;
    --h2-line-height: 1.2;
  }

  /* Button */
  --btn-primaryColor: var(--primaryColor);
  --btn-primaryTextColor: var(--textColorOnPrimary);
  --btn-primaryHoverColor: #485593;
  --btn-primaryHoverTextColor: var(--textColorOnPrimary);
  --btn-primaryBorder: rgba(0, 0, 0, 0.16);
  --btn-secondaryColor: transparent;
  --btn-secondaryTextColor: var(--primaryTextColor);
  --btn-secondaryHoverColor: transparent;
  --btn-secondaryHoverTextColor: var(--primaryTextColor);
  --btn-secondaryBorder: transparent;
  --btn-font-family: font-medium;
  --btn-font-size: 14px;
  --btn-line-height: 16px;
  --buttonDisable: #eaeaea;

  /* Text Box Font Size and Line Height  */
  --txtbox-enabled: #eaeaea;
  --txtbox-disabled: #eaeaea;
  --txtbox-enabled-hover: color-mix(in lab, #eaeaea, #000 4%);
  --txtbox-border: rgba(0, 0, 0, 0.08);
  --txtbox-font-family: font-regular;
  --txtbox-font-size: 14px;
  --txtbox-line-height: 16px;
  --txtbox-focus-border: rgba(60, 102, 255, 0.8);
  --txtbox-focus-outline: rgba(60, 102, 255, 0.4);

  /* radio checkbox */
  --toggle-deactivated-background: linear-gradient(190deg, #d3d3d3 0%, #eaeaea 100%);
  --toggle-deactivated-border: #d2d2d2;
  --toggle-activated-background: linear-gradient(190deg, #3155d9 0%, #3c66ff 100%);
  --toggle-activated-border: #2d4fcc;

  /* Switch - Mat Slide Toggle */
  --switch-track-background: linear-gradient(190deg, #f3f3f3 0%, #fff 100%);
  --switch-track-border: #d2d2d2;
  --switch-unselected-background: #fff;
  --switch-unselected-border: #d2d2d2;
  --switch-selected-background: linear-gradient(190deg, #2b995b 0%, #34bb6f 100%);
  --switch-selected-border: #2a9659;

  /* severity colors that will be used throught out portal */
  --servity-0-color: #ddd;
  --servity-1-color: #34bb6f;
  --servity-2-color: #f3c300;
  --servity-3-color: #ff7b2e;
  --servity-4-color: #e71d36;

  /* severity colors to highlight that will be used throught out portal */
  --servity-0-highlight: rgba(221, 221, 221, 0.3);
  --servity-1-highlight: rgba(52, 187, 111, 0.3);
  --servity-2-highlight: rgba(243, 195, 0, 0.3);
  --servity-3-highlight: rgba(255, 123, 46, 0.3);
  --servity-4-highlight: rgba(231, 29, 54, 0.3);

  /* tabber */
  --tab-label-bg: #d3d8eb;
  --tab-bg: #d4d9ea;
  --active-tab-bg: #ecf0f6;
  --active-tab-border: #eaeaea;

  /*   Font Family   */
  --regular: font-regular, sans-serif;
  --medium: font-medium, sans-serif;
  --bold: font-bold, sans-serif;

  /* Box Shadow */
  --box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1), 0 11px 15px rgba(0, 0, 0, 0.07), 0 4px 20px rgba(0, 0, 0, 0.06);
  --card-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

  /* Other variables */
  --card-varient-background: rgba(101, 133, 245, 0.05);
  --light-silver: #eee;
  --dove-gray: #ddd;
  --silver-stone: #c3c1c1;
  --smoke-gray: #999;

  /* Table */
  --table-background: transparent;
  --table-textColor: var(--secondaryTextColor);
  --table-font-size: var(--body-font-size);
  --table-line-height: var(--body-line-height);
  --table-font-family: var(--regular);
  --table-row-hover: rgba(60, 102, 255, 0.05);
  --table-header-background: var(--primaryColor);
  --table-header-textColor: var(--textColorOnPrimary);
  --table-header-font-family: var(--medium);
  --table-header-sorted-background: color-mix(in lab, var(--primaryColor), #000 40%);

  /* Display */
  --display-1-font-size: 98px;
  --display-1-line-height: 112px;
  --display-2-font-size: 61px;
  --display-2-line-height: 70px;
  --display-3-font-size: 49px;
  --display-3-line-height: 56px;
  --display-4-font-size: 35px;
  --display-4-line-height: 40px;
}
