.ngb-more-dropdown {
  box-shadow: var(--card-box-shadow);

  @include media('up', md) {
    &.right {
      right: 0 !important;
      left: initial !important;
    }
  }

  .dropdown-item {
    @include body-text;
    @include align-center;

    border-radius: 0;
    padding: 5px 20px;

    &.ngb-dropdown-close {
      padding: 0;
      position: absolute;
      inset: 5px 10px 0 auto;
    }

    &:focus {
      border: none;
    }

    i-feather {
      position: absolute;
      left: 24px;
    }

    .ngb-dropdown-text {
      display: inline-block;
      padding-left: 32px;
    }
  }
}

.vital-verticle-2 {
  height: 16px;
  margin-right: 10px;
}

.current-date {
  gap: 20px;
  color: var(--secondaryTextColor);

  .schedule-time {
    gap: 20px;
  }

  .date-text,
  .today {
    font-size: 16px;
    font-family: font-medium, sans-serif;
  }
}

/* --- */

.hs-tab-card-container {
  @include scrollbar;
}

.hs-tab-content {
  padding: 5px;

  .card-base {
    position: relative;
  }
}

.hs-tab-card-title {
  border-bottom: 1px solid var(--separatorColor);
  padding-bottom: 16px;
  margin-bottom: 16px;
  gap: 20px;

  h2 {
    margin-bottom: 0;
  }
}

.hs-card-flex {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 0 3px 5px;
}

.hs-cardslist {
  overflow: auto;

  li {
    padding-block: 5px;
  }

  .sub-list {
    padding: 15px 0 0 15px;
  }

  .block-list {
    @include flex(column);

    padding-bottom: 15px;
    gap: 5px;
  }
}

.list-col-2 {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .list-col-2-item {
    width: calc(50% - 8px);

    @include flex(column);
  }
}

.icon-flex {
  display: flex;
  gap: 12px;
}

.status-circle {
  @include size(13px);

  min-height: 10px;
  min-width: 10px;
  border-radius: 13px;
  background-color: var(--silver-stone);
  display: inline-block;
}

.dialog-header-right {
  display: flex;
}

.dialog-header-icons {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--secondaryTextColor);
}

.alert-icon {
  color: var(--negativeColor);
  margin-left: 5px;
}

.medication-modal-subtitle {
  min-height: 96px;
  border-bottom: 1px solid var(--separatorColor);
  margin-bottom: 16px;
}

.counter-time {
  color: var(--secondaryTextColor);
}

.alert-time {
  color: var(--secondaryTextColor);
}

app-alert-severity .highcharts-legend-item>text {
  font-family: font-regular, sans-serif !important;
  font-weight: normal !important;
  line-height: 1.15;
  letter-spacing: 0.048px;
  color: var(--secondaryTextColor) !important;
  fill: var(--secondaryTextColor) !important;
}

app-monograph {
  .container-fluid {
    padding-inline: 0;
    overflow: hidden;
  }

  .display-1 {
    font-size: 24px;
    color: var(--primaryTextColor);
  }
}

.alert-card-title {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

/* --- progress bar --- */


.mat-mdc-progress-bar-fill {
  border-radius: 14px;
  overflow: hidden;
}

.criteria-progress-bar.mat-mdc-progress-bar {
  border-radius: 10px;
  height: 8px;
}


.full-bar .mat-mdc-progress-bar-fill::after {
  background-color: var(--positiveColor);
}

.guided-session-expended .guided-session-wrapper {
  min-width: 1100px;
}

.allergy_select {
  .form-check-flex {
    &+& {
      margin-bottom: 18px;
    }
  }
}

@include media('down', md) {
  .ngb-more-dropdown {
    .dropdown-item {
      padding-inline: 10px;

      i-feather {
        left: 14px;
      }
    }
  }

  .alert-card-title {
    flex-direction: column;
  }

  .hs-tab-card-title {
    padding-bottom: 10px;
    margin-bottom: 12px;
    gap: 12px;

    h2 {
      @include ellipsis();
    }
  }

  .list-col-2 .list-col-2-item {
    flex-grow: 1;
  }

  .container-fluid {
    padding-inline: 12px;
  }
}

@include media('down', sm) {
  .list-col-2 .list-col-2-item {
    width: 100%;
  }

  .current-date {
    gap: 8px;
  }
}