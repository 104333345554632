.ngx-json-viewer .segment {
    padding: 3px 8px !important;
    margin-left: 8px !important;
    border-left: 1px dashed var(--separatorColor);
  
    .segment-main {
      .segment-key {
        font-family: var(--bold);
      }
  
      .toggler {
        height: 16px;
        width: 16px;
        background-image: url('../../images/icons/chevron-down.svg');
        background-size: 100% 100%;
        background-color: var(--fgColor);
        transform: rotate(-90deg) !important;
        transition: transform 0.3s ease-in-out;
        margin-top: 1px !important;
        margin-left: -17px !important;
  
        &::after {
          content: '' !important;
        }
  
        & + .segment-key {
          color: var(--primaryTextColor) !important;
        }
      }
  
      &.expanded .toggler {
        transform: rotate(0deg) !important;
      }
  
      .segment-value {
        white-space: normal;
        word-break: break-word;
      }
    }
  }
  