.tooltip-inner {
  font-family: font-regular, sans-serif;
  background-color: var(--tooltipColor);

  p:last-child {
    margin-bottom: 0;
  }
}

.tooltip-auto {
  .tooltip-inner {
    max-width: none;
  }
}

.rl-align {
  display: flex;
  gap: 12px;
  margin-bottom: 8px;

  span {
    text-align: left;
    width: calc(50% - 6px);

    &:first-child {
      text-align: right;
    }
  }
}

.tooltip-auto {
  .tooltip-inner {
    width: auto;
  }
}

.tooltip.show {
  opacity: 1;
}

.criteria-tooltip-table {
  @include media('down', md) {
    &.show {
      max-width: 95%;
      overflow-x: auto;
    }
  }

  table {
    color: var(--whiteTextColor);
    font-size: var(--small-font-size);
    text-align: left;

    .header-row {
      border-bottom: 1px solid currentColor;
    }

    th {
      font-family: var(--bold);
      font-weight: 400;
    }

    th,
    td {
      border-right: 1px solid currentColor;
      padding-inline: 6px;
      padding-block: 8px;

      &:last-child {
        border-right: 0;
      }
    }
  }
}
