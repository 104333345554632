.video-lobby-dialog {
  transition: all 150ms ease-in-out;
}

.local-stream,
.video-iframe {
  video {
    object-fit: contain !important;
  }
}

.video-lobby-dialog:has(.pip-card) .mat-mdc-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background: #626262;
    cursor: move;
    padding: 0 !important;
    overflow: hidden;

    .hs-tab-card-title {
      border-bottom: none;
      padding: 16px 10px !important;
      margin-bottom: 0;

      .dialog-header-icons {
        i-feather {
          color: var(--whiteTextColor);
        }
      }
    }

    .record-video {
      color: var(--whiteTextColor);
    }

    .local-stream video,
    .video-iframe video {
      object-fit: cover !important;
    }
  }
}

.video-lobby-dialog:not(:has(.pip-card)) {
  --vl-dialog-height: 98vh;
  --vl-dialog-block-padding: 20px * 2;

  .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface {
    max-height: var(--vl-dialog-height) !important;
  }

  .vl-dialog-container {
    height: calc(var(--vl-dialog-height) - var(--vl-dialog-block-padding));
  }

  .video-lobby-wrap {
    height: calc(100% - 56px);
  }
}

.group-icons {
  @include align-center(10px);
}

.vdo-call {
  transform: rotate(135deg);
  color: var(--textColorOnPrimary);
  position: relative;
  margin: 0 12px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: var(--negativeColor);
    z-index: -1;
  }
}

@include media('down', md) {
  .video-lobby-dialog:has(.pip-card) .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      background: #626262;
      cursor: move;
      padding: 0 !important;
      overflow: hidden;
    }
  }

  .video-lobby-dialog:not(:has(.pip-card)) {
    .video-lobby-wrap {
      height: calc(100% - 10px);
    }
  }
}
