.gs-inactive{
  background-color: #efefef;
}

.gs-active{
  background-color: var(--fgColor);
}

@include media('down', md) {
  .guided-session-detail-modal {
    .expended-question-set-title {
      padding-inline: 12px;
    }
  }

  /* guided session 'title-row' fixed in mobile expanded */

  .full-screen-dialog.guided-session-expended {
    .title-row-outer {
      height: 75px;
      border-bottom: 1px solid var(--separatorColor);
      background-color: var(--coreGrayBackground);
      border-right: 1px solid var(--separatorColor);

      .title-row {
        position: absolute;
        left: 0;
        gap: 10px;
        border-bottom: none;
        width: calc(100vw - 24px);
        height: 75px;
        background-color: transparent;
        align-items: center;
      }

      .title {
        strong {
          @include line-clamp(2);
        }

        span {
          @include line-clamp(1);
        }
      }
    }
  }
  
  /* 'title-row' end */
}

@include media('down', xl) {
  .guided-session-expended {
    .mat-mdc-dialog-content {
      display: initial !important;
      margin: 0 !important;
      padding: 0 !important;
      max-height: 100% !important;
      overflow: initial !important;
    }
  }
}
