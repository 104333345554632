.chart-cards-container {
  .chart-card-header {
    align-items: baseline;
  }

  .chart-card-header-title {
    @include ellipsis();

    .title-truncate {
      position: absolute;
      max-width: calc(100% - 70px);
      left: 39px;
      display: flex;
      align-items: center;
    }

    h2 {
      @include ellipsis();
    }
  }
}

.expended-vital-signs {
  .hs-card-flex {
    flex-wrap: wrap;
  }
}

.edit-lbs,
.edit-lbs:hover,
.edit-lbs:focus {
  width: 80px;
  font-size: 20px;
  text-align: center;
  border-width: 0 0 2px 0 !important;
  border-radius: 0;
  padding-block: 0;
  border-color: var(--txtbox-border) !important;
  margin-right: 4px;
  border-style: solid !important;
  box-shadow: none;
  background-color: transparent !important;
  padding-inline: 5px;
  outline: none !important;

  &.edit-bp {
    width: 45px;
  }
}

input.edit-lbs:not(.disabled):not(input[type='checkbox']):not(:read-only):focus {
  border-width: 0 0 2px 0 !important;
  border-color: var(--txtbox-border) !important;
}

.alert-class {
  color: var(--negativeColor);
}

.recorded-vitals-signs {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-family: font-regular, Arial, sans-serif;
  letter-spacing: 0.004em;
  color: var(--secondaryTextColor);

  strong {
    color: var(--primaryTextColor);
  }

  &-seperator {
    color: var(--disabledTextColor);
  }
}

.dialog-header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-card-varient {
  width: calc(20% - 18px);
  background: linear-gradient(0deg, var(--card-varient-background), var(--card-varient-background)), var(--fgColor);
  box-shadow: var(--card-box-shadow);
  border-radius: 16px;
  background-color: var(--fgColor);
  padding: 20px;
  position: relative;
}

.update-record {
  &.edit {
    position: absolute;
    inset: -1px 0 0 0;
    padding: 20px;
    border-radius: 16px;

    @include flex(column);
    @include card-bg-gradient;

    .edit-number {
      flex-grow: 1;
    }

    .edit-vitals {
      color: var(--secondaryTextColor);
      font-family: var(--regular);
      text-align: center;
    }
  }

  .chart-card-header-title .title-truncate {
    max-width: calc(100% - 140px);
    margin-top: -1px;
  }
}

@include media('down', xl) {
  .chart-card-varient {
    width: calc(33% - 18px);
    max-width: none;
  }
}

@include media('down', lg) {
  .chart-card-varient {
    width: calc(50% - 10px);
  }
}

@include media('down', md) {
  .chart-card-header-varient {
    flex-direction: column;
    position: relative;

    .dialog-header-left {
      h2 {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--separatorColor);
        align-self: stretch;
      }
    }

    .dialog-header-icons {
      position: absolute;
      top: 0;
      right: 0;
    }

    .dialog-header-left {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .dialog-header-right {
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }

  .update-record.edit {
    padding: 17px 10px;
  }

  .chart-card-varient {
    padding: 16px 10px;
  }

  .edit-lbs,
  .edit-lbs:hover,
  .edit-lbs:focus {
    width: 80px !important;
  }
}

@include media('down', sm) {
  .chart-card-varient {
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }
}
