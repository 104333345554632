@import 'src/assets/scss/base/_mixin.scss';

.calender-card-info {
  margin-bottom: 24px;
  font-size: 16px;
}

.calendar-slide-toggle {
  @include align-center(24px);

  font-size: 16px;
  margin-bottom: 24px;
}

.calendar-schedule {
  @include flex(column);

  .calendar-schedule-item {
    @include align-center(24px);

    height: 36px;
  }

  strong {
    font-size: 16px;
    text-align: right;
    min-width: 300px;
  }
}

.input-icon-inside {
  position: relative;

  input {
    width: 100%;
  }
}

.business-hrs {
  @include align-center(16px);

  span {
    color: var(--secondaryTextColor);
  }
}

textarea.form-control {
  width: 100%;
}

.demo-tab-content {
  overflow-x: hidden;
  padding-bottom: 8px;
}

.reset-setting {
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  gap: 5px;

  i-feather[name='rotate-ccw'] {
    color: var(--secondaryTextColor);
    margin-right: 4px;
  }
}

.date-picker .form-control.is-invalid {
  background-image: none !important;
}

@include media('down', sm) {
  .business-hrs {
    gap: 8px;
  }
}
