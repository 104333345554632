@include scrollbar();

.communication-cards {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  .card {
    padding: 30px;
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background-color: var(--txtbox-enabled-hover);
  }
}

.replacements-list {
  padding-inline: 0 !important;
  color: var(--secondaryTextColor);
  padding-block: 20px;

  h4 {
    font-family: font-medium, sans-serif;
    padding: 0 16px 16px;
    border-bottom: 1px solid var(--separatorColor);
    color: var(--primaryTextColor);
  }
}

.list-with-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.list-with-icon-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 12px 16px;

  span {
    margin-left: 16px;
  }
}

.communication-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;

  .communication-card-icons {
    display: flex;
    gap: 16px;
    color: $secondary;
  }
}

.email-tooltip {
  padding-block: 5px;

  p {
    margin-bottom: 0;
    text-align: center;
    font-size: var(--small-font-size);
  }
}

.communication-card-content {
  font-size: var(--body-font-size);
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form-group {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
    }
  }

  .communication-card-content-item {
    display: flex;
    gap: 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .communication-card-body {
    font-size: var(--body-font-size);
  }

  .communication-card-label {
    min-width: 110px;
    text-align: right;
    font-weight: 400;
    font-family: 'font-bold', sans-serif;
    font-size: 16px;
    letter-spacing: -0.1px;
  }
}

.add-form {
  .form-control {
    max-width: 100%;
  }

  .form-inner {
    font-size: var(--body-font-size);

    .label-add {
      display: inline-block;
      margin-bottom: 0.5rem;
      font-size: var(--body-font-size);
    }

    .cancel.btn-primary {
      background-color: var(--buttonDisable);
      border-color: var(--buttonDisable);
      color: var(--primaryTextColor);
    }

    .btn:disabled {
      background-color: var(--buttonDisable);
      border-color: var(--buttonDisable);
      color: var(--disabledTextColor);
    }
  }
}

.highlightTableColor {
  background-color: var(--table-row-hover) !important;
}

.communicationView-dialog {
  height: 465px;

  .mat-mdc-dialog-container {
    padding: 16px;
  }
}
