h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
}

p {
  @include body-text();
}

h1,
.h1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-family: var(--medium);
  color: var(--primaryTextColor);
}

h2,
.h2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-family: var(--bold);
  color: var(--primaryTextColor);
  margin-bottom: 16px;
}

h3,
.h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: var(--bold);
  color: var(--secondaryTextColor);
  margin-bottom: 16px;
}

h4,
.h4 {
  @include body-text(var(--bold));

  color: var(--primaryTextColor);
}

/* ***  Text and paragraph  css * */

a {
  color: var(--linkTextColor);
  text-decoration: none !important;
}

a:hover {
  color: var(--linkTextColor);
}

.link {
  color: var(--linkTextColor);
  cursor: pointer;
}

strong,
.strong {
  font-family: font-bold, sans-serif;
  font-weight: 400;
}

/* ---  display --- */

.display-1 {
  font-family: 'font-bold', sans-serif;
  font-size: var(--display-1-font-size);
  line-height: var(--display-1-line-height);
  letter-spacing: -0.005em;
  color: var(--primaryColor);
}

.display-2 {
  font-family: 'font-bold', sans-serif;
  font-size: var(--display-2-font-size);
  line-height: var(--display-2-line-height);
  letter-spacing: -0.005em;
  color: var(--primaryColor);
}

.display-3 {
  font-size: var(--display-3-font-size);
  line-height: var(--display-3-line-height);
  font-family: 'font-bold', sans-serif;
  color: var(--primaryColor);
}

.display-4 {
  font-size: var(--display-4-font-size);
  line-height: var(--display-4-line-height);
  font-family: 'font-bold', sans-serif;
  color: var(--primaryColor);
}