.site-tab-group {
  overflow-x: hidden;

  // .mat-mdc-tab-header {
  //   margin-bottom: 24px;
  //   border-bottom: 0;

  //   .mat-mdc-tab-label {
  //     min-width: 75px;
  //     font-family: font-medium, sans-serif;
  //     background-color: transparent !important;
  //     padding: 0;
  //     margin-inline: 22px;
  //     opacity: 1 !important;
  //     color: var(--secondaryTextColor);

  //     &:first-child {
  //       margin-left: 5px;
  //     }

  //     &.mat-mdc-tab-label-active {
  //       font-family: font-bold, sans-serif;
  //     }
  //   }
  // }

  .mat-mdc-tab-body {
    padding: 0 0 15px;

    .mat-mdc-tab-body-content {
      overflow-x: hidden;
    }
  }
}

.md-row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.settings-cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 5px;
  padding-inline: 3px;

  .card-base {
    padding: 30px;
  }
}

.replacements-list {
  padding-inline: 0 !important;
  color: var(--secondaryTextColor);

  h4 {
    font-family: font-medium, sans-serif;
    padding: 0 16px 20px;
    border-bottom: 1px solid var(--separatorColor);
  }
}

.list-with-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .list-with-icon-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 12px 16px;
  }
}

.settings-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;

  .settings-card-icons {
    display: flex;
    gap: 20px;
    color: var(--secondaryTextColor);
  }
}

.alert-message p {
  position: absolute;
  background: var(--tooltipColor);
  color: var(--textColorOnPrimary);
  text-align: left;
  min-width: 277px;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: var(--small-font-size);
  top: -85px;
  left: -130px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.alert-message:hover p {
  opacity: 1;
}

.settings-card-content {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form-group {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
    }
  }

  .settings-card-content-item {
    display: flex;
    gap: 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .settings-card-label {
    min-width: 115px;
    text-align: right;
  }
}

.alert-message {
  position: relative;
  float: left;
}

.alert-message p::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--tooltipColor);
}

.replacements {
  @include align-center(4px);

  cursor: pointer;
  padding-top: 15px;

  span {
    color: var(--linkTextColor);
  }

  .add-replacement-icon {
    color: var(--secondaryTextColor);
  }
}

@include media('down', md) {
  .site-tab-group {
    .mat-mdc-tab-header {
      margin-bottom: 12px;

      .mat-mdc-tab-label {
        min-width: 0;
        margin-inline: 12px;
        height: 32px;
      }
    }

    .mat-mdc-tab-body {
      padding: 0 0 15px;
    }
  }
}
