/* ====== Card Style ====== */
.account-pages {
  min-height: calc(100vh - 60px);

  @supports (height: 100svh) {
    min-height: calc(100svh - 60px);
  }

  .card {
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    width: 408px;

    &-body {
      padding: 48px;
    }

    &.create-account {
      width: 560px;
    }

    &.two-step-verification {
      width: 416px;
    }
  }

  h1 {
    font-family: font-bold, sans-serif;
  }

  button {
    min-width: 118px;
  }

  .form-group {
    .form-control:not(select) {
      max-width: 100%;
      width: 100%;
      background-image: none;
    }
  }

  .space-bottom-8 {
    padding-bottom: 8px;
  }

  .auth-form-container {
    padding-top: 24px;
  }
}

.auth-logo {
  margin: 76px 0 42px;
  display: inline-block;

  img {
    width: 160px;
  }
}

.custom-wrapper {
  @include flex-center;
}

/* ====== forgot password ====== */

.content-forgotPassword {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--primaryTextColor);
  letter-spacing: 0.0025em;
}

/* ====== activate account ====== */
.p-footer {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  color: var(--secondaryTextColor);
  letter-spacing: 0.004em;
}

@include media('down', md) {
  .account-pages {
    padding: 0 16px;

    .auth-logo {
      margin: 0 0 20px;
    }

    .card-body {
      padding: 24px;
    }
  }
}

.terms-services-dialog {
  &.cdk-overlay-pane {
    margin-top: initial !important;
  }
}

.terms-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: var(--primaryTextColor);
    font-family: font-medium, sans-serif;
  }
}
