/* ---checkbox with label and icon--- */
.form-check-flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;

  .verticle-2 {
    margin: 3px 5px 0 0;
  }
}

/* --- --- */

.form-check {
  display: block;
  position: relative;
  text-align: left;
  margin-bottom: 16px;

  .form-check-input {
    float: left;
    margin: 0 0 0 -1.5em;
    position: absolute;
    border: 1px solid var(--separatorColor);
  }

  .form-check-label {
    margin: 1px 0 4px 12px;
    color: var(--primaryTextColor);
  }
}

.form-check-input[type='checkbox'] {
  border-radius: 7px;

  @include check-radio();

  &:checked {
    background:
      url('../../images/icons/check-white.svg') center center,
      var(--toggle-activated-background);
  }

  &:disabled {
    opacity: 1;
  }
}

/* mat-checkbox */
.mat-mdc-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  left: 1px;
}

// .mat-mdc-checkbox-frame {
//   appearance: none;
//   height: 21px;
//   width: 21px;
//   border: 0.5px solid #adb5bd;
//   border-width: 1px !important;
//   border-bottom-color: var(--buttonDisable);
//   background: linear-gradient(180deg, #d3d3d3 0%, var(--buttonDisable) 100%);
//   border-radius: 7px !important;
// }

.mat-mdc-checkbox-checked {
  .mat-mdc-checkbox-checkmark {
    opacity: 0 !important;
    background: none !important;
  }
}

.mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background: none !important;
}

.mat-mdc-checkbox-indeterminate {
  .mat-mdc-checkbox-frame {
    border-color: var(--toggle-activated-border);
    border-bottom-color: var(--toggle-activated-border);
    background: var(--toggle-activated-background) !important;
  }
}

.mat-mdc-checkbox-layout {
  margin: 0 1.25px 0 0 !important;
}

.mdc-checkbox__native-control {
  width: 21px !important;
  height: 21px !important;
}

.mat-mdc-checkbox-touch-target {
  top: 10px !important;
  height: 20px !important;
  left: 10px !important;
  width: 20px !important;
}

.mdc-checkbox {
  width: auto !important;
  height: auto !important;

  .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    width: 21px;
    height: 21px;
    border-radius: 7px;
    background: var(--toggle-activated-background) !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: var(--toggle-activated-border) !important;
    border-width: 0 0 2px 0 !important;

    .mdc-checkbox__checkmark {
      padding: 2px;
    }

    .mdc-checkbox__mixedmark {
      margin-inline: 2px;
    }
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    background: var(--toggle-deactivated-background) !important;
    border: 1px solid var(--toggle-deactivated-border) !important;
    border-bottom-width: 2px !important;
  }

  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    background: var(--toggle-deactivated-background) !important;
    border-color: var(--toggle-deactivated-border) !important;
  }
}

.mat-mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-checkbox-ripple,
.mdc-checkbox:hover .mdc-checkbox__ripple,
.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  display: none !important;
}
