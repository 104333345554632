.mat-calendar-body-cell-content,
.mat-date-range-input-separator,
.mat-calendar-body-label,
.mat-calendar-table,
.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
  @include sm-text;

  color: var(--secondaryTextColor);
}

.mat-calendar-body-cell-content {
  top: 4% !important;
  left: 4% !important;
  width: 80% !important;
  height: 80% !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  text-align: left;
  background: var(--fgColor);
}

.fc .fc-col-header-cell-cushion {
  @include body-text;

  font-weight: 400;
  padding: 10px;
}

.mat-calendar-body-today.mat-calendar-body-selected,
.mat-calendar-body-selected {
  box-shadow: none !important;
  background-color: var(--primaryColor);
  color: var(--textColorOnPrimary);

  &::after {
    background-color: var(--textColorOnPrimary) !important;
  }
}

.mat-calendar-table-header th {
  @include sm-text;

  color: var(--disabledTextColor) !important;
  padding: 0 !important;
}

.mat-calendar-table-header-divider::after {
  display: none;
}

.mat-calendar-body {
  @include body-text;
}

.fc table {
  @include sm-text;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
}

th.fc-day-today,
th.fc-day-today .fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion {
  background-color: var(--primaryColor) !important;
  color: var(--textColorOnPrimary) !important;
}

.fc .fc-daygrid-day-number {
  margin-right: 4px;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-top a.fc-daygrid-day-number {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @include size(24px);

  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  background-color: var(--primaryColor);
  color: var(--textColorOnPrimary);
}

.fc .fc-timegrid-slot {
  height: 40px;
}

.fc .fc-timegrid-slot-label {
  border-top-style: none;
  vertical-align: top;
  padding-top: 4px;
}

.form-check-flex {
  input {
    margin-right: 0 !important;
  }
}

.leftSide-miniCalendar {
  td.mat-calendar-body-label[colspan='7'] {
    display: none !important;
  }

  td.mat-calendar-body-label[colspan]:not([colspan='7']) {
    visibility: hidden !important;
  }
}

.fc .fc-scroller-liquid-absolute {
  inset: 0;
  position: initial;
  overflow-y: visible;
}

/* events chips */

// .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events,
.fc .fc-more-popover .fc-popover-body {
  @include flex(column, null, flex-start);

  min-height: 1px;
  padding-right: 0;
  gap: 7px;
  align-self: stretch;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events,
.fc .fc-more-popover .fc-popover-body {
  gap: 4px;
  max-width: 98%;
}

.fc-daygrid-event {
  z-index: initial;
}

.fc-daygrid-event-harness {
  display: flex;
  max-width: inherit;
}

.fc .fc-more-popover {
  @include flex(column, null, flex-start);

  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  background: var(--fgColor) !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  box-shadow: var(--box-shadow) !important;

  .fc-popover-header {
    @include flex(null, null, flex-start);

    padding: 0;
    gap: 16px;
    background-color: transparent;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -16px;
      width: 100%;
      height: 0.8px;
      background-color: var(--separatorColor);
    }
  }

  .fc-popover-title {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    font-family: font-bold;
    color: var(--primaryTextColor);
    margin: 0;
  }

  .fc-popover-close {
    font-size: 24px;
    color: var(--secondaryTextColor);
  }

  .fc-popover-body {
    min-width: 100%;
    padding: 0;
    margin-top: 16px;

    .fc-daygrid-event-harness {
      min-width: 100%;

      .fc-event > div {
        max-width: 220px;
      }
    }
  }
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 2px;
}

.full-calendar .fc-event {
  padding-inline: 0;
  max-width: inherit;
  min-width: 100%;
  border: none;
  background: none;
  z-index: initial;

  div.pointer {
    @include flex(column, null, flex-start);
    @include sm-text;

    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    background: var(--coreGrayBackground);
    color: var(--secondaryTextColor) !important;
    height: 100%;
    min-width: 100%;
  }
}

.fc-day-past {
  background: var(--coreGrayBackground) !important;
}

.fc-day-disabled,
.fc-day-past {
  div.pointer {
    border: 1px solid var(--separatorColor);
  }
}

.fc-timegrid-event {
  margin-bottom: 0;
  border-radius: 4px;

  .fc-event-main {
    @include ellipsis();

    padding: 0 !important;
  }
}

.fc-daygrid-dot-event {
  padding: 0;
}

.fc-h-event .fc-event-main {
  // @include ellipsis();

  color: var(--secondaryTextColor);
}

.fc-event-selected,
.fc-event:focus {
  box-shadow: none;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event:focus,
.fc-daygrid-dot-event:visited,
.fc-event-selected::after,
.fc-event:focus::after {
  background: transparent;
}

.fc {
  .fc-daygrid-more-link {
    @include sm-text;

    color: var(--primaryColor);

    &:hover {
      background-color: transparent;
    }
  }
}

/* for calendar date picker */

.date-picker {
  .mat-datepicker-toggle {
    position: absolute;
    top: -2px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .mat-mdc-icon-button {
      @include size(20px);

      line-height: 20px;
      padding: 0;
      background: url('/assets/images/icons/calendar.svg') no-repeat;
      background-position: 50% 50%;
      background-size: 20px;

      svg {
        display: none;
      }
    }
  }

  input.mat-datepicker-input {
    max-width: 116px;
    margin-right: 0;
  }

  .mat-mdc-button-ripple.mat-ripple,
  .mat-mdc-button-focus-overlay {
    display: none;
  }
}

.calendar-autoComplete.mat-mdc-autocomplete-panel {
  @include flex(column, null, flex-start);

  gap: 16px;
  min-width: 240px !important;
  border-radius: 16px !important;
  padding: 16px 20px !important;
  max-height: 400px !important;
  overflow-x: hidden !important;
  box-shadow: var(--box-shadow) !important;
}

label {
  font-family: font-regular, sans-serif !important;
}

.option-style.mat-mdc-option {
  font-size: var(--body-font-size) !important;
  line-height: var(--body-line-height) !important;
  font-family: font-regular, sans-serif !important;
  color: var(--secondaryTextColor) !important;
  background-color: var(--fgColor) !important;
  margin: 8px 0;
  height: auto !important;
  padding: 8px 16px !important;

  .mdc-list-item__primary-text {
    div {
      @include align-center(10px);

      img {
        @include size(37px);

        border-radius: 100%;
      }
    }
  }
}

.calendar-autoComplete.mat-mdc-autocomplete-panel {
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: transparent !important;
  }
}

.mat-mdc-optgroup {
  .mat-mdc-optgroup-label {
    font-size: var(--body-font-size) !important;
    line-height: var(--body-line-height) !important;
    font-family: font-regular, Arial, sans-serif !important;
    font-weight: bold !important;
    color: var(--secondaryTextColor) !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
    height: auto !important;
    min-height: auto !important;
  }

  & + .mat-mdc-optgroup {
    margin-top: 8px;
  }
}

.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.toggle-check label {
  margin-top: 0 !important;
}

.date-picker .mat-datepicker-toggle {
  svg {
    width: 20px;
    height: 20px;
    color: var(--secondaryTextColor) !important;
  }
}

.custom-datepicker-header {
  margin-bottom: 8px !important;
  gap: 4px !important;

  i-feather {
    @include size(18px);

    line-height: 1;
  }

  .example-header-label {
    font-size: var(--small-font-size);
  }
}

.mat-calendar-content {
  padding: 0 !important;
}

.custom-date-class {
  border-radius: 100%;
}

.custom-date-class {
  border-radius: 100%;

  .mat-focus-indicator {
    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 3px);
      height: 3px;
      width: 3px;
      background-color: var(--disabledTextColor);
      margin-top: 16px;
      border-radius: 50%;
    }

    .mat-focus-indicator.mat-calendar-body-selected {
      &::after {
        background-color: var(--fgColor);
      }
    }
  }
}

.form-group {
  .custom-select-arrow {
    display: initial;

    .mat-mdc-select {
      display: flex;
      width: 210px;
    }

    mat-select.form-control {
      border: 1px solid transparent;
      box-shadow:
        0 -1px 1px var(--txtbox-border) inset,
        0 1px 0 #fff;
      border-radius: 8px;
    }
  }
}

.mat-mdc-select-min-line {
  display: block;
}

.mat-mdc-select-min-line,
.mat-mdc-select-value-text,
.mat-mdc-select {
  font-family: font-regular, sans-serif;
  color: var(--secondaryTextColor);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.mat-mdc-select-arrow-wrapper {
  height: auto !important;
}

.mat-mdc-select-min-line::first-letter,
.mat-mdc-select-value-text::first-letter,
.mat-mdc-option-text::first-letter {
  text-transform: uppercase;
}

.fc-daygrid-day-frame:not(td.fc-timegrid-axis ~ td.fc-daygrid-day .fc-daygrid-day-frame) {
  max-height: 130px;
}

@include media('down', lg) {
  .cdk-overlay-pane.full-calendar-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100%;
    height: 100%;

    .mat-mdc-dialog-container {
      padding: 0 !important;
      box-shadow: none !important;
      max-height: 100vh !important;
      min-height: 100vh;
      border-radius: 0 !important;
      padding-inline: 0;

      .mat-mdc-dialog-surface.mdc-dialog__surface {
        border-radius: 0 !important;
        padding: 12px !important;
      }
    }

    .mat-mdc-dialog-content {
      max-height: 100% !important;
      overflow: visible !important;
      padding: 0;
    }

    .mobile-content-scroll {
      display: flex;
      flex-direction: column;
      max-height: 100%;

      .mat-mdc-dialog-content {
        flex-grow: 1;
        max-height: none !important;
        overflow: auto !important;
      }
    }
  }

  .fc-view-harness.fc-view-harness-active {
    height: 100vh !important;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 4px 2px;
  }

  .custom-datepicker-header i-feather {
    padding: 2px 4px !important;
  }
}

@include media('down', md) {
  .date-picker .mat-datepicker-toggle .mat-mdc-icon-button {
    background-size: 16px;
  }
}

@include media('down', sm) {
  .fc .fc-more-popover {
    width: 90%;

    .fc-popover-header {
      width: 100%;
    }
  }

  .fc .fc-col-header-cell-cushion {
    font-size: var(--small-font-size);
  }

  .date-picker input.mat-datepicker-input {
    box-sizing: border-box;
    max-width: 116px !important;
  }
}
