@import 'src/assets/scss/base/_mixin.scss';

/* icon size should be only 24,20,16. */

i-feather.feather-24 {
  @include size(24px);
}

i-feather.feather-20 {
  @include size(20px);
}

i-feather.feather-16 {
  @include size(16px);
}

/* Base url path of icon folder */

$common-path: '/assets/images/icons/';

/* Icon list */

$icons: (
  'pdf': 'pdf',
  'healthwise': 'healthwise',
  'reset': 'reset',
  'resend': 'icon-resend',
  'suggested-message': 'suggested-message',
  'invitation': 'invitation',
  'record': 'icon-record',
  'calendar-today': 'calendar-today',
  'month': 'month-icon',
  'week': 'week-icon',
  'today': 'day-icon',
  'eating-habit': 'eating-habit',
  'share-screen': 'share-sreen-icon',
  'share-screen-white': 'share-screen-white',
  'unshare-screen': 'unshare-screen',
  'unshare-screen-white': 'unshare-screen-white',
  'accessibility': 'accessibility',
  'message-circle-off': 'message-circle-off',
  'share-screen-person': 'share-screen-person',
  'share-screen-person-user': 'share-screen-person-user',
  'share-screen-person-overlay-inside': 'share-screen-person-overlay-inside',
  'share-screen-person-overlay-outside': 'share-screen-person-overlay-outside',
  'dashboard': 'dashboard',
  'audit-trail': 'audit-trail',
  'referral': 'referral',
  'system-check': 'system-check',
  'system-check-crossout': 'system-check-crossout',
  'system-check-nodata': 'system-check-nodata',
);

@each $icon-name, $icon-url in $icons {
  i-feather[name='#{$icon-name}'],
  i-feather.#{$icon-name} {
    background: url('#{$common-path}#{$icon-url}.svg') no-repeat center 100%/100%;
  }
}

i-feather[name='pdf'],
i-feather.pdf {
  background-size: auto 100%;
}

i-feather[name='resend'] {
  &.feather-20 {
    width: 24px;
  }
}

i-feather[name='more-vertical'] {
  color: var(--secondaryTextColor);
}

i-feather[name='record'] {
  &.active {
    background: url('#{$common-path}record.svg') no-repeat center 100%/100% !important;
  }
}

i-feather[name='referral'],
.referral {
  &.status-preferred {
    background-image: url('#{$common-path}referral-preferred.svg');
  }

  &.disabled,
  &:disabled {
    background-image: url('#{$common-path}referral-disabled.svg');
  }
}

i-feather[name='record'],
.record {
  &.disabled,
  &:disabled {
    background-image: url('#{$common-path}icon-record-disabled.svg');
  }
}

.mat-mdc-icon-button {
  i-feather {
    svg {
      width: inherit;
      height: inherit;
    }
  }
}

.dialog-header-icons {
  i-feather {
    cursor: pointer;
  }
}

@include media('down', md) {
  i-feather {
    height: 24px !important;
    width: 24px !important;
    padding: 4px;
    background-size: 16px 16px !important;
    background-position: center center !important;
    background-repeat: no-repeat;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .tbl-sort {
    height: 24px;
    width: 24px;

    i-feather {
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  .feather-reset,
  .btn,
  .site-header,
  .section-multi-links,
  .dropdown-content,
  .custom-chips,
  .open-time-picker,
  .block-chips,
  .mat-mdc-menu-item,
  .msg-user-name-icon,
  .tabs-header__tabs,
  .ngb-more-dropdown,
  .mat-icon,
  .time-input,
  .mat-expansion-panel-header,
  .language-select,
  .sub-input,
  .replacements,
  .detail-repeatable .content-row {
    i-feather {
      padding: 0;
      height: 16px !important;
      width: 16px !important;
    }
  }

  .icon-in-input {
    padding: 4px;
    height: 24px !important;
    width: 24px !important;
    bottom: 5px !important;
  }
}
