/* {##################   Map for Body Inline Spacing   ###################} */

$content-inline-padding: (
  lg: 30px,
  sm: 12px,
);

/* {##################   flex   ###################} */

@mixin flex($direction: null, $justify: null, $align: null) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($gap: null, $justify: null, $align: null) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-center($direction: row, $align: center, $justify: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin align-center($gap: null) {
  display: flex;
  align-items: center;
  gap: $gap;
}

/* {##################   ellipsis/truncate   ###################} */

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($line: 2, $overflow: hidden) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: $overflow;
}

/* {##################   Scrollbar   ###################} */

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(195, 193, 193, 0.5);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--silver-stone);
  }
}

/* {##################   Breakpoints   ###################} */

$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

/* Mixin for generating media queries */
@mixin media($type, $size1, $size2: null) {
  $breakpoint-size1: map-get($breakpoints, $size1);

  @if not map-has-key($breakpoints, $size1) {
    @error "Breakpoint name '#{$size1}' does not exist in the breakpoints map.";
  }

  @if $type =='up' {
    @media (min-width: $breakpoint-size1) {
      @content;
    }
  }

  @else if $type =='down' {
    @media (max-width: $breakpoint-size1) {
      @content;
    }
  }

  @else if $type =='between' {
    @if $size2 ==null {
      @error "Type 'between' requires two breakpoint sizes.";
    }

    @if not map-has-key($breakpoints, $size2) {
      @error "Breakpoint name '#{$size2}' does not exist in the breakpoints map.";
    }

    $breakpoint-size2: map-get($breakpoints, $size2);

    @media (min-width: $breakpoint-size1) and (max-width: ($breakpoint-size2 - 0.02px)) {
      @content;
    }
  }
}

/* Mixin for generating media queries based on orientation */
@mixin mediaOrientation($orientation, $minWidth, $maxWidth: null, $maxHeight: null) {

  /* Check if the orientation is valid */
  @if $orientation !='landscape' and $orientation !='portrait' {
    @error "Invalid orientation: '#{$orientation}'. Please use 'landscape' or 'portrait'.";
  }

  $breakpoint-size1: map-get($breakpoints, $minWidth);
  $breakpoint-size2: if($maxWidth !=null, map-get($breakpoints, $maxWidth), null);

  /* Orientation query */
  $orientation-query: '(orientation: #{$orientation})';

  /* Generate media query */
  @media #{$orientation-query} {
    @if $maxWidth !=null {
      @media (min-width: #{$breakpoint-size1}) and (max-width: #{$breakpoint-size2}) {
        @content;
      }
    }

    @else {
      @media (min-width: #{$breakpoint-size1}) {
        @content;
      }
    }
  }
}

/*
// Usage example
@include mediaOrientation('landscape', sm, md) {
  // Styles for landscape mode with min-width: sm and max-width: md
}

@include mediaOrientation('portrait', xs, sm, 600px) {
  // Styles for portrait mode with min-width: xs, max-width: sm, and max-height: 600px
}
*/

/* breakpoints code end*/

/* {##################   Font   ###################} */

@mixin body-text($font-family: font-regular) {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-family: $font-family;
}

@mixin sm-text($font-family: font-regular) {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-family: $font-family;
  letter-spacing: 0.004em;
}

/* {##################   card varient bg   ###################} */

@mixin card-bg-gradient {
  background: linear-gradient(0deg, var(--card-varient-background), var(--card-varient-background)), var(--fgColor);
}

/* {##################   size   ###################} */

@mixin size($size) {
  width: $size;
  min-width: $size;
  height: $size;
}

/* {##################   Triangle   ###################} */

@mixin triangle($size, $color: #fff, $direction: null) {
  position: absolute;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  top: -$size;

  @if $direction ==bottom {
    border-bottom: 0;
    border-top: $size solid $color;
  }

  @else {
    border-top: 0;
    border-bottom: $size solid $color;
  }
}

@mixin res-inline-input() {
  .form-group-inline {
    flex-direction: column;
    align-items: initial;

    .form-group:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

@mixin view-detail-responsive() {
  .view-detail-item {
    flex-direction: column;
    gap: 8px;
    padding-bottom: 10px;
  }

  .view-detail-label {
    text-align: left;
  }
}