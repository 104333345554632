.video-lobby-widget-wrapper {
  .hs-tab-card-title {
    border-bottom: 1px solid var(--separatorColor);
    padding-bottom: 16px;
    margin-bottom: 16px;
    gap: 20px;
  }

  .video-lobby-left {
    .video-list {
      .video-card {
        border-width: 0 0 1px 0 !important;
        padding: 16px 0 8px !important;

        .video-widget-row {
          @include flex(row, null, flex-start);

          margin-bottom: 8px;

          &.space-between {
            justify-content: space-between !important;
          }

          gap: 10px;

          h1 {
            font-size: var(--h3-font-size);
            line-height: var(--h3-line-height);
            font-family: var(--bold);
          }
        }

        .list-item.mb-2,
        .video-event-title.mb-2,
        .flex-between.mb-1,
        h1.clr-secondary.mb-1 {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
