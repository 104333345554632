.ticket-wrapper {
  @include flex(null, null, flex-start);

  height: calc(100% - 62px);
  gap: 16px;
  color: var(--secondaryTextColor);

  .message-list {
    max-height: calc(100% - 50px);
    overflow-y: auto;

    .message-item {
      padding: 20px;
      border: 1px solid var(--separatorColor);

      @include align-center(14px);

      & + .message-item {
        border-top: none;
      }

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: #f2f2f2;
      }
    }

    .message-item-text {
      flex-grow: 1;
    }

    .message-item-title {
      @include line-clamp(1, hidden);

      font-family: font-bold, sans-serif;
      width: calc(100% - 115px);
    }

    .message-icon {
      @include size(37px);

      min-width: 37px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .message-details {
    flex-grow: 1;
    height: 100%;
    width: 100%;

    .message-user {
      border-bottom: 1px solid var(--separatorColor);
      padding-bottom: 8px;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 10px;

        @include line-clamp(1, hidden);
      }
    }

    .msg-user-name-icon {
      @include line-clamp(1, hidden);

      margin-bottom: 6px;

      .msg-user-icon {
        margin-right: 12px;
      }
    }

    .message-history {
      overflow: auto;
      max-height: 240px;
      display: flex;
      flex-direction: column-reverse;
      transition: height 1s ease-in-out;
    }

    .full-message-icon {
      @include size(37px);

      border-radius: 50%;
      overflow: hidden;
    }

    .message-footer {
      border-top: 1px solid var(--separatorColor);
      background-color: var(--fgColor);
      margin-top: 16px;
      padding-top: 20px;
      padding-bottom: 0;
      position: relative;

      .message-footer-main {
        @include flex(row, space-between, center);

        gap: 13px;
      }

      .attachment-icons {
        gap: 8px;
        margin-top: 18px;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 8px;
      }

      .message-editor {
        width: 300px;
        flex-grow: 1;
      }

      button {
        padding-block: 8px;
      }
    }

    .attachment-icons-item {
      overflow: hidden;

      img {
        width: 100%;
        height: 100px;
        object-fit: contain;
      }

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        opacity: 0;
        transform: opacity 0.5s;
      }

      .attachment-cancel {
        display: none;
      }

      &:hover {
        &::before {
          opacity: 1;
        }

        .attachment-cancel {
          display: block;
          inset: initial;
          z-index: 10;
        }
      }
    }
  }

  label {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }

  .full-message-item {
    margin-bottom: 12px;
    margin-top: 1px;

    .full-message-content {
      display: flex;
      align-items: flex-start;
    }

    .full-message-top {
      @include align-center(20px);
    }

    .message-date-text {
      font-size: 11px;
      margin-left: 57px;
    }

    .message-container {
      background-color: #f2f2f2;
      padding: 12px 20px;
      max-width: 336px;
      width: 336px;
      line-height: 20px;
      position: relative;
      color: var(--secondaryTextColor);
      margin-bottom: 3px;
      border: 1px solid #f2f2f2;

      .message-text {
        word-break: break-word;
      }

      &::before {
        content: '';
        border-style: solid;
        border-width: 7px 10px 7px 0;
        border-color: transparent #f2f2f2 transparent transparent;
        position: absolute;
        left: -10px;
        top: calc(50% - 7px);
      }
    }

    &.right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .full-message-top {
        flex-direction: row-reverse;
      }

      .full-message-content {
        flex-direction: row-reverse;
      }

      .message-date-text {
        width: 345px;
        margin-left: 0;
        background-color: transparent;
      }

      .message-container {
        margin-right: 11px;
        margin-left: 0;
        background-color: var(--fgColor);
        border-color: var(--separatorColor);

        &::before {
          border-color: transparent transparent transparent var(--separatorColor);
          left: initial;
          border-width: 8px 0 9px 11px;
          right: -11px;
          top: calc(50% - 9px);
        }

        &::after {
          content: '';
          border-style: solid;
          border-width: 7px 0 7px 10px;
          border-color: transparent transparent transparent #fff;
          position: absolute;
          right: -9px;
          top: calc(50% - 7px);
        }
      }
    }
  }

  .message-detail-area {
    height: auto;
  }
}

@include media('down', md) {
  .ticket-wrapper {
    .full-message-item {
      .message-container {
        max-width: 100%;
        width: 100%;
        padding: 8px;
      }
    }

    .message-details {
      .message-history {
        height: calc(100vh - 220px);
        max-height: 100%;
      }
    }
  }
}
