.card {
  background-color: var(--fgColor);
}

.card-base {
  box-shadow: var(--card-box-shadow);
  border-radius: 16px;
  background-color: var(--fgColor);
  padding: 24px;
  margin-bottom: 24px;
}

.mcard-header {
  h1 {
    font-family: font-medium, sans-serif;
  }
}

.common-card-header {
  padding-bottom: 24px;

  h2 {
    margin-bottom: 0;
  }
}

.card-varient {
  @include card-bg-gradient;

  width: calc(20% - 18px);
  min-height: 200px;
}

.card-titlebar {
  @include flex(null, flex-end, center);

  padding-bottom: 24px;
}

.filter-box {
  display: flex;
  align-items: center;

  .filter-label {
    font-family: var(--medium);
  }

  span {
    padding-right: 10px;
    color: var(--secondaryTextColor);
  }

  .filter-dropdown a {
    padding: 8px;
    color: var(--primaryTextColor);
  }
}

.title-bar {
  padding-top: 10px;
  padding-bottom: 24px;

  h2 {
    margin-bottom: 0;
  }

  .breadcrumb {
    margin-bottom: 0;
  }

  &.page-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 0;

    h1 {
      margin-bottom: 0;
    }
  }
}

.card-header-icons {
  @include flex(null, flex-end);

  gap: 16px;
  color: var(--secondaryTextColor);

  i-feather {
    cursor: pointer;
  }
}

.card-head {
  display: flex;
  gap: 24px;

  h2 {
    margin-bottom: 0;
  }

  .card-head-buttons {
    display: flex;
    gap: 10px;

    .btn {
      min-width: 64px;
      height: 27px;
      padding: 5px 10px;
      font-size: var(--body-font-size);
      letter-spacing: 0.035px;
    }
  }
}

@include media('down', md) {
  .card-base {
    padding: 12px;
    margin-bottom: 12px;
  }

  .mcard-header {
    align-items: baseline;
  }

  .common-card-header {
    align-items: baseline;
  }

  .card-header-icons {
    gap: 12px;
  }

  .card-titlebar {
    padding-bottom: 12px;
  }

  .filter-box {
    gap: 24px;
    flex-grow: 1;
    justify-content: space-between;

    .dropdown {
      padding-right: 0;
    }

    .btn-primary {
      @include align-center(4px);

      padding-inline: 8px;
    }
  }

  .title-bar {
    padding-bottom: 12px;
  }
}

@include media('down', sm) {
  .filter-box {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;

    span {
      padding-right: 0;
    }

    .filter-label {
      max-width: calc(100% - 110px);
      display: flex;
      text-wrap: nowrap;
      align-items: center;

      .dropdown {
        .dropdown-toggle span {
          max-width: calc(100% - 30px);
        }
      }
    }
  }
}
