@import 'src/assets/scss/base/mixin';

input.mat-mdc-input-element {
  @include txtbox();

  font-style: normal;
  letter-spacing: 0.0025em;

  /* padding: 8px 10px; */
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--txtbox-border);
  box-shadow:
    0 -1px 1px var(--txtbox-border) inset,
    0 1px 0 #fff;
  border-radius: 8px;
  display: block;
  box-sizing: border-box;
}

.mat-mdc-form-field-appearance-fill .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-mdc-slide-toggle-content {
  font-size: var(--small-font-size);
  font-family: font-regular, sans-serif;
}

.mat-mdc-icon-button {
  color: var(--secondaryTextColor) !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}

.custom-select-arrow {
  .mat-mdc-select.form-control:focus {
    @include input-focus();
  }
}

mat-option.mat-mdc-option:not(.mdc-list-item--disabled) {
  margin-block: 0;
  transition: 0.3s all;

  &.mdc-list-item--selected {
    background-color: #1967d2 !important;

    .mdc-list-item__primary-text {
      color: var(--whiteTextColor) !important;
    }
  }

  &:hover {
    background-color: #1967d2 !important;
    color: var(--whiteTextColor) !important;
  }
}

/*  mat date calendar input */

.date-icon {
  position: absolute;
  right: 10px;
  left: initial;
  bottom: 1px;
  color: var(--secondaryTextColor);
}

.date-icon .mat-mdc-icon-button,
.date-icon .mat-mdc-button-touch-target {
  height: unset !important;
  width: unset !important;
}
