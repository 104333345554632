.mat-accordion {
  .mat-expansion-panel {
    .mat-expansion-panel-header,
    .mat-expansion-panel-content {
      font-family: var(--regular);
    }
  }
}

.criteria-cell {
  .mat-accordion {
    width: 100% !important;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
    background: transparent !important;
    color: var(--secondaryTextColor);

    .mat-expansion-panel-body {
      padding: 0;
      opacity: 0;
      transition: all 0.5s;

      .last-updated {
        opacity: 0;
      }
    }

    &.mat-expanded {
      position: static !important;

      .mat-expansion-panel-body {
        opacity: 1;

        .last-updated {
          opacity: 1;
        }
      }
    }

    .mat-expansion-panel-header {
      padding-inline: 0;
      height: auto;

      &:hover {
        background: transparent !important;
      }

      &.mat-expanded {
        height: auto !important;
      }
    }
  }

  .mat-expansion-panel-content[style*='visibility: hidden'] * {
    height: 0;
  }

  .mat-content.mat-content-hide-toggle {
    margin-right: 0;
    justify-content: space-between;
  }
}

.ad-hoc-accordion {
  .mat-expansion-panel {
    padding: 16px;
  }
}

.mat-accordion.mat-accordion-base {
  .mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none !important;
    color: var(--secondaryTextColor);

    &.mat-expansion-panel-spacing {
      margin-block: 0;
    }

    .mat-expansion-panel-header {
      padding-inline: 0;
      font-size: var(--body-font-size);
      font-family: var(--regular);
      background: transparent !important;
      height: auto;
      color: var(--secondaryTextColor);

      &:hover {
        background: transparent;
      }

      &.mat-expanded {
        height: auto;
      }
    }

    .mat-expansion-panel-content {
      font-family: var(--regular);
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-indicator {
      width: 15px;

      &::after {
        padding: 4px;
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        position: relative;
        left: -8px;
      }
    }
  }
}

.table-responsive {
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    vertical-align: text-top;
  }
}
