@import './switch';

@mixin check-radio {
  margin-top: 0;
  cursor: pointer;
  background: var(--toggle-deactivated-background);
  border-color: var(--toggle-deactivated-border) !important;
  border-bottom-width: 2px !important;
  box-shadow: none;
  transition: none;

  @include size(20px);

  &:checked {
    outline: none;
    border-color: var(--toggle-activated-border) !important;
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}

@mixin input-hover {
  background-color: var(--txtbox-enabled-hover);
  color: var(--primaryTextColor);
}

@mixin input-focus {
  background-color: #fff;
  border: 1px solid var(--txtbox-focus-border);
  outline: 4px solid var(--txtbox-focus-outline);
}

@import './input';
@import './radio';
@import './checkbox';

.form-group + .form-group {
  margin-top: 16px;
}

label {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  letter-spacing: 0.0025em;
  cursor: unset;
  margin-bottom: 8px !important;
  color: var(--secondaryTextColor);
}

.mat-mdc-slide-toggle label {
  margin-bottom: 0 !important;
}

.required {
  color: var(--negativeColor) !important;
}

.invalid-feedback {
  display: block;
  color: var(--negativeColor);
}

.warning-feedback {
  display: block;
  color: var(--warningColor);
}

/* --- mat-select --- */

.form-group.form-group-mat-select {
  @include flex(column, null, flex-start);
}

.mat-mdc-form-field {
  font-family: font-regular, sans-serif !important;

  &.mat-form-field-appearance-fill {
    .mat-mdc-floating-label.mdc-floating-label {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      height: 33px !important;

      &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: var(--txtbox-enabled);
        padding: 0.5rem 0.5rem !important;
        border-radius: 8px;
        border-top: 0 solid transparent;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      }

      &.mdc-text-field--filled .mdc-line-ripple::before,
      &.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
        border-bottom-width: 0 !important;
      }

      .mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
      }

      &:hover .mat-mdc-form-field-focus-overlay {
        opacity: initial !important;
      }

      .mat-mdc-form-field-flex {
        width: 210px;
        // padding: 0.35rem 0.75rem;
      }

      // .mat-mdc-select-trigger {
      //     height: 1.5em;
      // }
    }
  }

  .mat-mdc-select-min-line,
  .mat-mdc-select-value-text,
  .mat-mdc-select {
    font-family: font-regular, sans-serif;
    color: var(--secondaryTextColor);
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);

    @include ellipsis;
  }

  &.current-time {
    &.mat-form-field-appearance-fill {
      .mat-mdc-form-field-infix {
        min-width: 134px;
        max-width: 210px;
        width: 100%;
      }
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

// .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
//   background-color: transparent !important;
// }

// .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple),
// .mat-mdc-option:focus.mdc-list-item,
// .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
//   background-color: rgba(0, 0, 0, 0.12) !important;
//   color: var(--primaryColor) !important;
// }

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: #fff !important;
}

.device-setting-select {
  .custom-select-arrow,
  .mat-mdc-select,
  .mat-mdc-form-field-flex,
  .mat-mdc-form-field,
  .mat-mdc-form-field-infix {
    width: 100% !important;
  }
}

.col-2-50 {
  display: flex;

  .col-2-inner {
    width: 50%;
  }
}

.time-input input {
  width: 100%;
}

/* input-with-unit reference used in edit-profile */

.input-with-unit {
  display: flex;
  align-items: center;
  gap: 5px;

  input[type='text'],
  input[type='number'] {
    width: 60px;
  }

  label {
    margin-bottom: 0;
  }
}

.profile-activation {
  .md-mat-select {
    .mat-mdc-form-field-label {
      padding: 3px 0 0 10px;
    }

    &.mat-mdc-form-field-should-float {
      .mat-mdc-form-field-label-wrapper {
        opacity: 0;
      }
    }
  }
}

.profile-activation-reasons {
  @include align-center(12px);

  padding-bottom: 2px;

  label {
    margin-bottom: 0 !important;
    flex-grow: 1;
  }
}

fieldset {
  padding-block: 16px;
  border-bottom: 1px solid var(--separatorColor);

  &:last-child {
    border-bottom: none;
  }
}

/*  #######   Search   #######  */

.search_dialog {
  position: relative;

  i-feather[name='search'] {
    position: absolute;
    left: 8px;
    bottom: 6px;
    color: var(--secondaryTextColor);
  }
}

.search-input {
  &.search-100 {
    width: 100%;

    .form-control {
      max-width: none;
      width: 100%;
    }

    i-feather[name='search'],
    i-feather[name='x'] {
      right: 12px;
      left: initial;
    }
  }
}

.select-days {
  display: flex;
  gap: 6px;

  button {
    &.btn.btn-days {
      @include flex(row, center, center);

      padding: 0 10px;
      width: 40px;
      height: 27px;
      background: var(--dove-gray);
      color: var(--disabledTextColor);
      font-family: var(--bold);

      &.selected {
        background-color: var(--primaryColor);
        color: var(--textColorOnPrimary);
      }
    }
  }
}

@include media('down', sm) {
  .select-days {
    gap: 4px;

    button.btn.btn-days {
      width: 35px;
    }
  }
}
