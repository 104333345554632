.editable-heading {
  display: inline-flex;
  position: relative;
  max-width: calc(100% - 50px);
  align-items: center;

  h1 {
    margin: 0;

    @include ellipsis;
  }

  input.editable {
    border: none !important;
    outline: none !important;
    background: transparent !important;
    padding: 0 20px 0 0;
    width: 100%;
    border-bottom: 2px solid transparent;
    box-shadow: none;

    &::placeholder {
      font-size: 24px;
      color: var(--disabledTextColor);
    }

    &:focus {
      border-bottom-color: var(--primaryColor);
    }
  }

  .feather-20 {
    margin-left: 5px;
  }

  .editable-actions {
    display: flex;
    gap: 12px;
    position: relative;

    i-feather {
      width: 14px;
      height: 14px;
    }
  }
}

// .editable-space {
//   flex-grow: 1;
//   visibility: hidden;
//   min-height: 32px;
// }

/* editable textarea for description */

.editable-desc {
  min-width: 250px;
  margin-bottom: 24px;

  textarea {
    min-height: 23px;

    &::placeholder {
      font-size: var(--body-font-size);
      font-family: var(--regular);
    }
  }
}

/* editable-inline  */

.editable-inline {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 24px;

  i-feather {
    color: var(--secondaryTextColor);
  }

  h2 {
    margin: 0;
    max-width: calc(100% - 50px);
  }

  p {
    color: var(--secondaryTextColor);
    font-size: var(--body-font-size);
    margin-bottom: 0;
  }

  .editable-icons {
    padding: 0 0 0 10px;
    display: flex;
    gap: 20px;
    color: var(--secondaryTextColor);
  }
}

.editable-description::placeholder {
  font-size: 14px !important;
}

/* --- ng editor --- */

/*
.ng-editor * {
  font-family: var(--regular) !important;
}

.ng-editor {
  strong {
    font-family: var(--bold) !important;
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  @extend .form-control;

  max-width: 100%;
  height: auto;

  &.ck-focused {
    color: #495057;
    border-color: var(--primaryColor) !important;
    box-shadow: 0 0 0 0.2rem rgb(34 107 252 / 25%) !important;
  }
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 8px !important;
  max-height: 260px;
  height: 260px;
  border-color: transparent !important;
  background-color: $input-bg;

  &.ck-focused {
    background-color: var(--fgColor);
  }
}
*/

@include media('down', md) {
  .editable-inline {
    margin-bottom: 20px;
    gap: 10px;

    h2 {
      @include ellipsis;
    }

    p {
      @include ellipsis;
    }

    .editable-icons {
      gap: 10px;
    }
  }
}
