.mat-mdc-tab-header {
  margin-bottom: 24px;
  border-bottom: 0;

  .mat-mdc-tab-label {
    min-width: 75px;
    font-family: font-medium, sans-serif;
    background-color: transparent !important;
    padding: 0;
    margin-inline: 22px;
    opacity: 1 !important;
    color: var(--secondaryTextColor);

    &:first-child {
      margin-left: 5px;
    }

    &.mat-mdc-tab-label-active {
      font-family: font-bold, sans-serif;
    }
  }
}

.severity-lavel-tab-group {
  .mat-mdc-tab-header {
    margin-bottom: 24px;

    .mat-mdc-tab {
      min-width: 125px;
      font-family: font-bold, sans-serif;
      background-color: transparent !important;
    }
  }
}

.mat-mdc-tab-group {
  $active-tab-height: 58px;

  height: 100%;
  font-family: font-regular, sans-serif;

  @include scrollbar;

  .mat-mdc-tab-list {
    flex-grow: 0 !important;

    .mat-mdc-tab-labels {
      gap: 44px;
    }

    .mdc-tab__text-label {
      font-size: 16px;
    }

    .mat-mdc-tab {
      height: 30px;
      padding: 0;
      margin-inline: 0;
      min-width: 30px;
      font-family: font-medium, sans-serif;
      background-color: transparent !important;
      color: var(--secondaryTextColor);

      .mdc-tab__content {
        padding-bottom: 10px;
        width: auto;
        justify-content: space-between !important;

        .mdc-tab__text-label {
          font-size: 16px;
          color: var(--secondaryTextColor);
          letter-spacing: 0.005em;
        }
      }

      .mdc-tab-indicator__content--underline {
        border-top-width: 3px;
        border-color: var(--primaryColor) !important;
      }

      &:hover .mdc-tab__ripple::before {
        opacity: 0;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        color: inherit;
        font-family: font-bold, sans-serif;
      }
    }
  }
}
