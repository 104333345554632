.radio-inline {
  display: flex;
  gap: 15px;

  label {
    margin-top: 3px;
  }
}

.radio-label {
  margin-top: 2px;
}

.form-check-input[type='radio'] {
  @include check-radio();

  position: relative;

  &:checked {
    background: var(--toggle-activated-background);

    &::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50%;
      top: 6px;
      left: 6px;
      content: '';
    }
  }

  &:disabled{
    opacity: 1;
  }
}