.dashboard-title-select {
  .mat-mdc-select {
    width: auto;
    max-width: 100%;
  }

  .mat-mdc-select-value-text,
  .mat-mdc-select-min-line {
    @extend h1;

    display: block;
    margin-bottom: 0;
    padding-inline-end: 5px;
  }
}

.cdk-overlay-pane:has(.dashboard-title-list) {
  min-width: 220px;
}

ngx-gridster-item {
  padding: 10px;
  margin: 0 !important;
}

.gridster-item-inner {
  height: 100%;
}

.error {
  color: var(--negativeColor);
}

.centerwidget i {
  font-size: 40px;
}

.panel-outer {
  padding: 3px;
  height: 100%;

  .panel-inner {
    @include flex-center(column);

    height: 100%;
    overflow-y: initial;

    &.card-base {
      padding: 16px 20px;
    }

    .card-head {
      align-self: flex-start;
    }
  }
}

.panel-body {
  @include flex-center;

  width: 100%;
  flex-grow: 1;

  iframe {
    margin-top: 10px;
  }
}

h2.panel-title {
  margin-bottom: 0;
}

.head {
  width: 100%;
  display: flex;

  .leftdata {
    width: calc(100% - 40px);
  }

  .rightdata {
    width: 40px;
  }
}

.widget-counter {
  display: flex;
  justify-content: space-around;
  gap: 12px;
  flex-grow: 1;

  .counter-item {
    @include flex(column);
  }
}

.page-title-select {
  font-size: 24px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  font-family: font-medium, sans-serif;
  margin-bottom: 15px;
  padding: 0;
  max-width: 100%;

  @include ellipsis();

  option {
    @include body-text();
  }
}

.dashboard-widget-header {
  border-bottom: 1px solid var(--separatorColor);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

@include media('down', md) {
  .dashboard-view {
    padding: 0;
  }

  .centerwidget i {
    font-size: 25px;
  }

  .head {
    .leftdata {
      width: calc(100% - 28px);
    }

    .rightdata {
      width: 28px;
    }
  }

  .panel-outer {
    .panel-inner.card-base {
      padding: 10px 12px;
    }
  }

  .dashboard-widget-header {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
