.view-detail-row {
  @include flex(column);

  gap: 16px;

  .view-detail-item {
    @include flex();

    gap: 24px;
    font-size: 16px;
    color: var(--primaryTextColor);
  }

  .view-detail-label {
    text-align: right;
    min-width: 150px;
  }

  .view-detail-info {
    font-family: var(--regular);
    word-break: break-word;
    line-height: 18px;
  }

  .view-detail-info-varient {
    font-size: var(--body-font-size);
  }

  @include media('down', sm) {
    @include view-detail-responsive();
  }
}