.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-center(row, center, space-between);
}

.pointer {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  color: var(--disabledTextColor);

  * {
    color: var(--disabledTextColor);
  }

  .form-control {
    color: var(--disabledTextColor);
  }
}

.seperator {
  border-bottom: 1px solid var(--separatorColor);

  &.no-lr-space {
    width: calc(100% + (map-get($content-inline-padding, lg) * 2));
    margin-left: -(map-get($content-inline-padding, lg));

    @include media('down', md) {
      width: calc(100% + (map-get($content-inline-padding, sm) * 2));
      margin-left: -(map-get($content-inline-padding, sm));
    }
  }
}

.body-text {
  @include body-text();
}

.sm-text {
  @include sm-text();
}

ul.list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* font-family utility classes */
/* output classes - .ff-regular, .ff-medium, .ff-bold */

$font-family: (
  'regular': 'font-regular',
  'medium': 'font-medium',
  'bold': 'font-bold',
);

@each $name, $ff in $font-family {
  .ff-#{$name} {
    font-family: $ff, sans-serif;
  }
}

/* white-space utility classes */
/* output classes - .ws-normal, .ws-nowrap, .ws-pre, .ws-pre-line, .ws-pre-wrap */

$white-space: normal, nowrap, pre, pre-line, pre-wrap;

@each $ws in $white-space {
  .ws-#{$ws} {
    white-space: $ws;
  }
}

/* rest values can be added if required in the below list */
/* output classes - .ov-visible, .ov-hidden, .ov-clip, .ov-auto, .ov-initial, .ov-unset */

$overflow: visible, hidden, clip, auto, initial, unset;

@each $ov in $overflow {
  .ov-#{$ov} {
    overflow: $ov !important;
  }
}
