.header-menu-dialog {
  .mat-mdc-dialog-container {
    min-width: 250px !important;
  }
}

@include media('down', sm) {
  .header-menu-dialog {
    margin-left: 0 !important;

    .mat-mdc-dialog-container {
      border-radius: 0 16px 16px 0 !important;
      max-height: calc(100dvh - 60px);
    }

    .mat-mdc-dialog-content {
      max-height: none;
    }
  }
}

  @include mediaOrientation('landscape', sm, null, 575px) {
  .header-menu-dialog {
    .mat-mdc-dialog-container {
      max-height: 80vh !important;
    }
  }
}
